import { useNavigate, useParams } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { useRequest } from 'ahooks';
import { Button, EllipsisSpan, Table, Wrapper, FormTitle, SubContent, usePaging, Paging } from '@maxtropy/components';
import {
  apiV2TestTaskAssessmentPost,
  apiV2TestTaskCaseLatestInfoPost,
  apiV2TestTaskCasePagePost,
} from '@maxtropy/pv-test-apis-v2';
import { DataType, buildColumns } from './util';
import IntelligentEvaluationCriteria from './components/IntelligentEvaluationCritria';
import { LevelType, LevelTypeDisplay } from '@/common/util';
import { isEmpty, isNil } from 'lodash';
import styles from './index.module.scss';
import { BreadCrumb } from '@maxtropy/rc-components';
import { Space } from 'antd';

const ViewTaskCase: React.FC = () => {
  const navigate = useNavigate();
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const { id: taskId } = useParams<{ id: string }>();

  const { data, loading } = useRequest(
    () =>
      apiV2TestTaskCasePagePost({
        taskId,
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        setTotalCount(res.total ?? 0);
        return res.list || [];
      }),
    {
      refreshDeps: [setTotalCount],
    }
  );

  const { data: assessmentData } = useRequest(() => apiV2TestTaskAssessmentPost({ id: taskId }), {
    refreshDeps: [taskId],
  });

  const { data: caseLatestInfo } = useRequest(() => apiV2TestTaskCaseLatestInfoPost({ id: taskId }), {
    refreshDeps: [taskId],
  });

  const columns: ColumnsType<DataType> = [
    ...buildColumns,
    {
      title: '标准要求',
      width: 120,
      dataIndex: 'standardRequirement',
      render: (v, record) => {
        const content = record.standardRequirement?.map(item => {
          return (
            <>
              <span style={{ fontWeight: 'bold' }}>
                {LevelTypeDisplay[item.level as LevelType]}（{item.score}分）
              </span>
              {item.desc ?? ''}；
            </>
          );
        });
        return (
          <EllipsisSpan
            className={styles.overFlowStyle}
            value={isEmpty(record.standardRequirement) ? undefined : content}
          />
        );
      },
    },
    {
      title: '测试步骤',
      width: 200,
      dataIndex: 'testProcedure',
      render: v => <EllipsisSpan className={styles.overFlowStyle} value={v} />,
    },
    {
      title: '结果',
      dataIndex: 'testResult',
      fixed: 'right' as const,
      width: 150,
      render: (v, record, index) => {
        const levelObj = record.standardRequirement?.find(item => item.level === v);
        if (!levelObj) {
          return '--';
        }

        return <EllipsisSpan value={`${LevelTypeDisplay[levelObj.level as LevelType]}（${levelObj.score ?? 0}分）`} />;
      },
    },
    {
      title: '操作',
      dataIndex: 'operation',
      width: 100,
      fixed: 'right' as const,
      render: (v, record) => (
        <Button type="link" disabled={isNil(record.fileKeys)}>
          查看资料
        </Button>
      ),
    },
  ];

  return (
    <Wrapper breadCrumb={<BreadCrumb options={[{ name: '查看任务测试用例' }]} />}>
      <FormTitle title="查看任务测试用例"></FormTitle>
      <SubContent>
        <div style={{ marginBottom: 10 }}>
          <span style={{ color: 'var(--mx-error-color)' }}>{caseLatestInfo?.stoppedCount ?? 0}</span>
          条用例已停用，
          <span style={{ color: 'var(--mx-warning-color)' }}>{caseLatestInfo?.updatingCount ?? 0}</span>
          条用例版本有更新（对应用例版本号已标星<span style={{ color: 'var(--mx-warning-color)' }}>*</span> ）
        </div>
        <Table rowKey="id" columns={columns} dataSource={data} loading={loading} scroll={{ x: 600 }} />
        <Paging pagingInfo={pagingInfo} />
        <IntelligentEvaluationCriteria data={assessmentData} />
        <Space size={8} className={'sticky-footer-left'}>
          <Button wrapStyle={{ marginTop: 32 }} onClick={() => navigate(-1)}>
            返回
          </Button>
        </Space>
      </SubContent>
    </Wrapper>
  );
};

export default ViewTaskCase;
