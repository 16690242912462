import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  DatePicker,
  Form,
  FormTitle,
  ShowInput,
  SubContent,
  Wrapper,
} from '@maxtropy/components';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { Col, Input, Row, Space } from 'antd';
import {
  apiV2TestTaskCreatePost,
  apiV2TestTaskDetailPost,
  apiV2TestTaskUpdatePost,
  V2TestTaskCreatePostRequest,
} from '@maxtropy/pv-test-apis-v2';
import { CaseType, HardwareType } from '@/common/util';
import { BreadCrumb } from '@maxtropy/rc-components';

const EditBaseInfo = () => {
  const [form] = Form.useForm();
  const { id: taskId } = useParams<{ id: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (isNil(taskId)) return;
    apiV2TestTaskDetailPost({ id: taskId }).then(res => {
      form.setFieldsValue({
        name: res?.name,
        sampleName: res?.sampleName,
        versionNo: res?.versionNo,
        sampleSource: res?.sampleSource,
        submissionDate: dayjs(String(res?.submissionDate)),
        applyPerson: res?.applyPerson,
        applyPersonAddress: res?.applyPersonAddress,
        manufacture: res?.manufacture,
        manufactureAddress: res?.manufactureAddress,
        testEnterprise: res?.testEnterprise,
        testPlace: res?.testPlace,
      });
    });
  }, [taskId]);

  const onFinish = () => {
    form.validateFields().then(res => {
      const params: V2TestTaskCreatePostRequest = {
        ...res,
        hardwareType: HardwareType.Inverter,
        type: CaseType.HARDWARE,
        id: taskId,
      };
      const Api = taskId ? apiV2TestTaskUpdatePost : apiV2TestTaskCreatePost;

      Api(params).then(() => navigate(-1));
    });
  };

  return (
    <Wrapper breadCrumb={<BreadCrumb options={[{name: `${taskId ?  '编辑' : '添加'}测试任务`}]}/>}>
      <FormTitle title={`${taskId ?  '编辑' : '添加'}测试任务`} />
      <SubContent>
        <Form form={form} layout="vertical">
          <Row>
            <Col span={8}>
              <Form.Item
                name="name"
                label="任务名称"
                rules={[
                  { required: true, message: '请输入' },
                  { max: 100, message: '不超过100个字符' },
                ]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item required label="任务类型">
                <ShowInput value={'硬件测试'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item required label="硬件类型">
                <ShowInput value={'逆变器'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="sampleName"
                label="样品名称"
                rules={[
                  { required: true, message: '请输入' },
                  { max: 100, message: '不超过100个字符' },
                ]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="versionNo"
                label="版本号"
                rules={[
                  { required: true, message: '请输入' },
                  { max: 100, message: '不超过100个字符' },
                ]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="sampleSource"
                label="样品来源"
                rules={[
                  { required: true, message: '请输入' },
                  { max: 100, message: '不超过100个字符' },
                ]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="submissionDate" label="送检日期" rules={[{ required: true, message: '请输入' }]}>
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="applyPerson"
                label="申请人"
                rules={[
                  { required: true, message: '请输入' },
                  { max: 100, message: '不超过100个字符' },
                ]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="applyPersonAddress"
                label="申请人地址"
                rules={[
                  { required: true, message: '请输入' },
                  { max: 100, message: '不超过100个字符' },
                ]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="manufacture"
                label="制造商"
                rules={[
                  { required: true, message: '请输入' },
                  { max: 100, message: '不超过100个字符' },
                ]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="manufactureAddress"
                label="制造商地址"
                rules={[
                  { required: true, message: '请输入' },
                  { max: 100, message: '不超过100个字符' },
                ]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="testEnterprise"
                label="测试单位"
                rules={[
                  { required: true, message: '请输入' },
                  { max: 100, message: '不超过100个字符' },
                ]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="testPlace"
                label="测试地点"
                rules={[
                  { required: true, message: '请输入' },
                  { max: 100, message: '不超过100个字符' },
                ]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Space size={8}>
          <Button type="primary" onClick={onFinish}>
            确定
          </Button>
          <Button onClick={() => navigate(-1)}>取消</Button>
        </Space>
      </SubContent>
    </Wrapper>
  );
};

export default EditBaseInfo;
