import { Button, Modal } from '@maxtropy/components';
import styles from '../index.module.scss';
import { useState } from 'react';
import { V2TestTaskAssessmentPostResponse } from '@maxtropy/pv-test-apis-v2';
import followImg from '../assets/follow@2x.png';

const IntelligentEvaluationCriteria = ({ data }: { data?: V2TestTaskAssessmentPostResponse }) => {
  const [open, setOpen] = useState<boolean>();

  return (
    <>
      <div className={`${styles.testResultCon} ${styles.testResultColor}`}>
        <div className={styles.resultTitle}>
          <span className={styles.label}>测试结果</span>
          <Button type="link" onClick={() => setOpen(true)}>
            智能级评定标准
          </Button>
        </div>
        <div>
          <span className={styles.label}>软件测试</span>累计用例
          <span className={styles.warningColor}>{data?.totalCount ?? 0}项 </span>，本次实测{' '}
          <span className={styles.successColor}>{data?.currentCount ?? 0}项</span>
          ，其中：
        </div>
        <div>
          {data?.assessGroupList?.map((item, index) => (
            <div style={{ marginTop: 8, width: '50%' }}>
              <span className={styles.dot} />
              <span className={styles.label}>{item.group}</span>
              <span>
                用例累计<span className={styles.warningColor}>{item.groupTotalCount ?? 0}项</span>，实测
                <span className={styles.successColor}>{item.groupCurrentCount ?? 0}项</span>，总分
                <span className={styles.warningColor}>{item.groupTotalScore ?? 0}分</span>
                ，实际得分<span className={styles.successColor}>{item.groupCurrentScore ?? 0}分</span>
              </span>
            </div>
          ))}
        </div>

        <div style={{ marginTop: 8 }}>
          <span className={styles.successColor}>{data?.assessResult}</span>
        </div>
      </div>
      <Modal
        title="智能级评定标准"
        open={open}
        footer={
          <Button type="primary" onClick={() => setOpen(false)}>
            知道了
          </Button>
        }
        size="normal"
        onCancel={() => setOpen(false)}
        className={styles.testResultColor}
        bodyScroll
      >
        <img className={styles.followImg} src={followImg} alt="" />
      </Modal>
    </>
  );
};

export default IntelligentEvaluationCriteria;
