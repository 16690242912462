import { CustomFilter, EllipsisSpan, Form, Input, Paging, Select, Table, usePaging } from '@maxtropy/components';
import React, { useState } from 'react';
import {
  apiV2PlatformInverterPagePost,
  apiV2PlatformPvAreaListPost,
  apiV2PlatformPvListPost,
  V2PlatformInverterPagePostRequest,
  V2PlatformInverterPagePostResponse,
} from '@maxtropy/pv-test-apis-v2';
import { useRequest } from 'ahooks';
import { ColumnsType } from 'antd/es/table';
import { isEmpty } from 'lodash';
import { FormInstance } from 'antd';

interface SearchParams extends Omit<V2PlatformInverterPagePostRequest, 'page' | 'size'> {}
export type DataType = Exclude<V2PlatformInverterPagePostResponse['list'], undefined>[number];

interface InverterTableProp {
  bottomTitle?: string;
  setSelectedRowKeys: (v: number[]) => void;
  form: FormInstance<any>;
}

const columns: ColumnsType<DataType> = [
  {
    title: '逆变器编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'deviceName',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '设备类型',
    dataIndex: 'deviceType',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
];

const InverterTable = ({ setSelectedRowKeys, form, bottomTitle }: InverterTableProp) => {
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<SearchParams>({});
  const pvStationId = Form.useWatch('pvStationId', form);
  const [selectedRows, setSelectedRows] = useState<DataType[]>();

  const { data: pvList } = useRequest(() => apiV2PlatformPvListPost({}).then(res => res.list ?? []), {
    refreshDeps: [],
  });

  const { data: areaList } = useRequest(() => apiV2PlatformPvAreaListPost({id: pvStationId}).then(res => res.list ?? []), {
    refreshDeps: [pvStationId],
  });

  const { data, loading } = useRequest(
    () =>
      apiV2PlatformInverterPagePost({
        ...searchParams,
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        setTotalCount(res.total ?? 0);
        return res.list || [];
      }),
    {
      refreshDeps: [searchParams, setTotalCount, pageOffset, pageSize],
    }
  );

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedRowKeys((selectedRows || []).map(item => item.deviceId) as number[]);
      setSelectedRows(selectedRows);
    },
  };

  const onFinish = (v: SearchParams) => {
    setSearchParams(v);
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({});
    setPageOffset(1);
  };

  const filters = (
    <CustomFilter<SearchParams> form={form} onFinish={onFinish} onReset={onReset} colSpan={8}>
      <Form.Item name="code" label="编号">
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item name="pvStationId" label="光伏站">
        <Select
          placeholder="请选择"
          onChange={() => {
            form.setFieldsValue({
              areaId: undefined,
            });
          }}
          options={pvList?.map(item => ({ label: item.name, value: item.id }))}
        />
      </Form.Item>
      <Form.Item name="areaId" label="片区">
        <Select placeholder="请选择" options={areaList?.map(item => ({ label: item.name, value: item.id }))} />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <>
      {filters}
      <Form>
        <div>
          <Table
            rowSelection={{
              type: 'radio',
              ...rowSelection,
            }}
            rowKey={record => record.id + '' + record.deviceId}
            columns={columns}
            dataSource={data}
            loading={loading}
            bottomLeftExtra={
              bottomTitle && !isEmpty(selectedRows) ? (
                <>
                  已选择{bottomTitle}： {selectedRows![0]?.code} {selectedRows![0]?.deviceName}
                </>
              ) : (
                <div />
              )
            }
          >
            <Paging pagingInfo={pagingInfo} />
          </Table>
        </div>
      </Form>
    </>
  );
};

export default InverterTable;
