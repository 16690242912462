import { CustomFilter, EllipsisSpan, Form, Input, Modal, Paging, Select, Table, usePaging } from '@maxtropy/components';
import React, { Key, useState } from 'react';
import { useRequest } from 'ahooks';
import {
  apiV2TestCasePagePost,
  apiV2TestTaskBatchLinkCasePost,
  V2TestCasePagePostRequest,
  V2TestCasePagePostResponse,
} from '@maxtropy/pv-test-apis-v2';
import { ColumnsType } from 'antd/es/table';
import { isEmpty } from 'lodash';
import { HardWareAbilityDomainItem } from '..';
import {
  AbilityDomainDisplay,
  AbilityDomainType,
  CaseType,
  HardwareType,
  ReferentType,
  ReferentTypeDisplay,
} from '@/common/util';

interface SelectCaseModalProp {
  onCancel: () => void;
  updateFn: () => void;
  taskId?: string;
  abilityDomainData?: HardWareAbilityDomainItem[];
}

interface SearchParams extends Omit<V2TestCasePagePostRequest, 'page' | 'size'> {}
type DataType = Exclude<V2TestCasePagePostResponse['list'], undefined>[number];

const baseColumns: ColumnsType<DataType> = [
  {
    title: '用例编号',
    dataIndex: 'code',
    render: v => <EllipsisSpan value={v} />,
    width: 150,
  },
  {
    title: '用例名称',
    dataIndex: 'name',
    width: 100,
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '所属能力域',
    dataIndex: 'abilityDomain',
    width: 100,
    render: (v: AbilityDomainType) => <EllipsisSpan value={AbilityDomainDisplay[v]} />,
  },
  {
    title: '测试指标(单位)',
    dataIndex: 'testIndicatorDesc',
    width: 140,
    render: (v, record) => <EllipsisSpan value={v} />,
  },
  {
    title: '参照对象',
    dataIndex: 'referent',
    width: 100,
    render: (v: ReferentType) => <EllipsisSpan value={ReferentTypeDisplay[v]} />,
  },
];

const SelectCaseModal = ({ onCancel, updateFn, abilityDomainData, taskId }: SelectCaseModalProp) => {
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [form] = Form.useForm();

  const [searchParams, setSearchParams] = useState<SearchParams>({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>();

  const { data, loading } = useRequest(
    () =>
      apiV2TestCasePagePost({
        ...searchParams,
        type: CaseType.HARDWARE,
        hardwareType: HardwareType.Inverter,
        taskId: taskId!,
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        setTotalCount(res.total ?? 0);
        return res.list || [];
      }),
    {
      refreshDeps: [searchParams, setTotalCount],
    }
  );

  const rowSelection = {
    onChange: (selectedRowKeys: Key[], selectedRows: DataType[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedRowKeys(selectedRowKeys as number[]);
    },
  };

  const onOk = () => {
    if (isEmpty(selectedRowKeys)) {
      onCancel();
      return;
    }

    apiV2TestTaskBatchLinkCasePost({
      taskId: taskId!,
      ids: selectedRowKeys,
    }).then(() => {
      onCancel();
      updateFn();
    });
  };

  const onFinish = (v: SearchParams) => {
    setSearchParams({
      ...v,
      name: v.name === '' ? undefined : v.name,
    });
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({});
    setPageOffset(1);
  };

  const filters = (
    <CustomFilter<SearchParams> form={form} onFinish={onFinish} onReset={onReset} colSpan={8}>
      <Form.Item name="name" label="名称/编号">
        <Input placeholder={'请输入编号或名称查询'} />
      </Form.Item>
      <Form.Item name="abilityDomain" label="所属能力域">
        <Select
          placeholder="请选择"
          mode="multiple"
          options={(abilityDomainData || []).map(({ id, name }) => ({ label: name, value: id }))}
        />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Modal title="选择用例" size="big" bodyScroll open={true} onCancel={onCancel} onOk={onOk}>
      {filters}
      <Form>
        <div>
          <Table
            rowSelection={{
              ...rowSelection,
            }}
            rowKey="id"
            columns={baseColumns as ColumnsType<DataType>}
            dataSource={data}
            loading={loading}
          />
          <Paging pagingInfo={pagingInfo} />
        </div>
      </Form>
    </Modal>
  );
};

export default SelectCaseModal;
