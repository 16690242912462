import { useTenantPermissions } from "@maxtropy/rc-utils";
import { useEffect, useState } from "react";

export enum JudgmentMethod {
  ARTIFICIAL = 1, // 人工判断
  SYSTEM = 2, // 系统计算
}

export const JudgmentMethodDisplay = {
  [JudgmentMethod.ARTIFICIAL]: '人工判断',
  [JudgmentMethod.SYSTEM]: '系统计算',
};

export enum StatusType {
  DISABLE = 0, // 停用
  ENABLE = 1, // 启用
}

export const StatusDisplay = {
  [StatusType.DISABLE]: '停用',
  [StatusType.ENABLE]: '启用',
};

export const statusOptions = [
  { label: '全部', value: null },
  ...Object.entries(StatusDisplay).map(([k, v]) => ({
    label: v,
    value: Number(k),
  })),
];

export enum AbilityDomainType {
  HARDWARE_PERFORMANCE = 17, // 硬件性能
  INTELLIGENT_CONTROL = 18, // 智能控制
  DATA_TRANSMISSION = 19, // 数据传输
}

export const AbilityDomainDisplay = {
  [AbilityDomainType.HARDWARE_PERFORMANCE]: '硬件性能',
  [AbilityDomainType.INTELLIGENT_CONTROL]: '智能控制',
  [AbilityDomainType.DATA_TRANSMISSION]: '数据传输',
};

export enum IntelligentLevel {
  BASIC = 1,
  OPERATE,
  ASSISTEd,
  HIGHLY,
  COMPLETED,
}

export const IntelligentLevelDisplay = {
  [IntelligentLevel.BASIC]: '基础智能级',
  [IntelligentLevel.OPERATE]: '操作智能级',
  [IntelligentLevel.ASSISTEd]: '辅助智能级',
  [IntelligentLevel.HIGHLY]: '高度智能级',
  [IntelligentLevel.COMPLETED]: '完全智能级',
};

export const intelligentLevelOptions = [
  ...Object.entries(IntelligentLevelDisplay).map(([k, v]) => ({
    label: v,
    value: Number(k),
  })),
];

export enum TaskStatusType {
  TESTING = 1, // 测试中
  COMPLETED = 2, // 已完成
}

export const TaskStatusDisplay = {
  [TaskStatusType.TESTING]: '测试中',
  [TaskStatusType.COMPLETED]: '已完成',
};

export const taskStatusOptions = [
  ...Object.entries(TaskStatusDisplay).map(([k, v]) => ({
    label: v,
    value: Number(k),
  })),
];

export enum CaseType {
  SOFTWARE = 1,
  HARDWARE,
}

export const CaseTypeDisplay = {
  [CaseType.SOFTWARE]: '软件测试',
  [CaseType.HARDWARE]: '硬件测试',
};

export enum HardwareType {
  Component = 1,
  Inverter,
}

export const HardwareTypeDisplay = {
  [HardwareType.Component]: '组串/组件',
  [HardwareType.Inverter]: '逆变器',
};


export enum ReferentType {
  CONTROL_GROUP = 1,
  THEORETICAL_VALUE = 2
}

export const ReferentTypeDisplay = {
  [ReferentType.CONTROL_GROUP]: '对照组',
  [ReferentType.THEORETICAL_VALUE]: '理论值'
}

// 等级
export enum LevelType {
  A = 1, // A类
  B, // B类
  C, // C类
  D, // D类
}

export const LevelTypeDisplay = {
  [LevelType.A]: 'A类',
  [LevelType.B]: 'B类',
  [LevelType.C]: 'C类',
  [LevelType.D]: 'D类',
};

export function useHasPermission(key: string) {
  const [status, setStatus] = useState<boolean>();
  const permissions = useTenantPermissions();
  useEffect(() => {
    setStatus((permissions ?? []).findIndex(i => i === key) !== -1);
  }, [permissions, key]);
  return status;
}