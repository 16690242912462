import { Button, Modal } from '@maxtropy/components';
import styles from '../../../ComponentTestManagement/TestCase/index.module.scss';
import { useState } from 'react';
import { HardwareType, HardwareTypeDisplay } from '@/common/util';
import { V2TestTaskAssessmentPostResponse } from '@maxtropy/pv-test-apis-v2';
import { isEmpty } from 'lodash';

const IntelligentEvaluationCriteria = ({
  data,
  type,
}: {
  type: HardwareType;
  data?: V2TestTaskAssessmentPostResponse;
}) => {
  const [open, setOpen] = useState<boolean>();

  return (
    <>
      <div className={`${styles.testResultCon} ${styles.testResultColor}`}>
        <div className={styles.resultTitle}>
          <span className={styles.label}>测试结果</span>
          <Button type="link" onClick={() => setOpen(true)}>
            智能级评定标准
          </Button>
        </div>
        <div>
          <span className={styles.label}>光伏{HardwareTypeDisplay[type]} </span>累计用例
          <span className={styles.warningColor}>{data?.totalCount ?? 0}项 </span>，本次实测{' '}
          <span className={styles.successColor}>{data?.currentCount ?? 0}项</span>
          ，其中：
        </div>
        <div className={styles.index}>
          <span className={styles.dot} />系统计算用例<span className={styles.successColor}>{data?.systemTotalCount ?? 0}项</span>，
          {!isEmpty(data?.assessGroupList) && (
            <>
              {data!.assessGroupList!.map((item, index) => (
                <div>
                  <span className={styles.label}>{item.group}</span>
                  <span className={styles.successColor}>({item.groupCurrentScore ?? 0}分)</span>
                  {index === (data?.assessGroupList?.length ?? 0) - 1 ? '' : '、'}
                </div>
              ))}
              ，
            </>
          )}
          总分 <span className={styles.successColor}>{data?.systemTotalScore ?? 0}分</span>
        </div>
        <div className={styles.index}>
          <span className={styles.dot} />人工判断用例<span className={styles.successColor}>{data?.manualTotalCount ?? 0}项</span>，
          总分 <span className={styles.successColor}>{data?.manualTotalScore ?? 0}分</span>
        </div>
        <div>
          累计得分
          <span className={styles.successColor}>{data?.groupCurrentScore ?? 0}分，</span>产品符合 <span className={styles.successColor}>{data?.assessResult}要求</span>
        </div>
      </div>
      <Modal
        title="智能级评定标准"
        open={open}
        footer={
          <Button type="primary" onClick={() => setOpen(false)}>
            知道了
          </Button>
        }
        onCancel={() => setOpen(false)}
        className={styles.testResultColor}
      >
        <div className={styles.label} style={{ marginBottom: 16 }}>
          {HardwareTypeDisplay[type]}等级划分标准
        </div>
        <div style={{ display: 'grid', gap: '8px' }}>
          <div>
            智能I级: <span className={styles.label}> 得分区间 </span>
            <span className={styles.warningColor}>(0,33%]</span>
          </div>
          <div>
            智能II级: <span className={styles.label}> 得分区间 </span>
            <span className={styles.warningColor}>(33,66%]</span>
          </div>
          <div>
            智能III级: <span className={styles.label}> 得分区间 </span>
            <span className={styles.warningColor}>(66,100%]</span>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default IntelligentEvaluationCriteria;
