import { useState } from 'react';
import { Modal, message, Form, Table, EllipsisSpan, Button } from '@maxtropy/components';
import { apiV2TestTaskImportProofMaterialPost } from '@maxtropy/pv-test-apis-v2';
import { isNil } from 'lodash';
import { CloseCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Space, Upload } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';

type Props = {
  taskId?: number | string;
  setOpen: (visible: boolean) => void;
  updateFn: () => void;
};

const columns = [
  {
    title: '用例编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '用例名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '自评等级',
    dataIndex: 'level',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ?? '--'} />,
  },
  {
    title: '问题描述',
    dataIndex: 'description',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v} />,
  },
];

const UploadProve = ({ setOpen, updateFn, taskId }: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorList, setErrorList] = useState<any[]>([]);

  const onFinish = () => {
    if (isNil(taskId)) return;

    form.validateFields().then(() => {
      const { fileKey } = form.getFieldsValue();
      setLoading(true);
      apiV2TestTaskImportProofMaterialPost({ fileKey, id: Number(taskId) })
        .then(res => {
          message.success('导入成功');
          updateFn?.();
          setOpen(false);
        })
        .finally(() => setLoading(false));
    });
  };

  const handleChange = (info: UploadChangeParam) => {
    const { status } = info.file;

    if (status === 'done' || status === 'removed') {
      const list: string[] = info.fileList.map(i => i.response?.data);

      form.setFieldsValue({
        fileKey: list,
      });
    }
  };

  const beforeUpload = (file: any) => {
    const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png', 'image/jpg'];
    const isAllowedType = allowedTypes.includes(file.type);

    if (!isAllowedType) {
      return Upload.LIST_IGNORE;
    }

    return true; // 返回 true 继续上传, 返回 false 阻止上传
  };

  return (
    <Modal
      title="导入证明材料"
      open={true}
      onCancel={() => setOpen(false)}
      contentClassName="modal-form-content"
      confirmLoading={loading}
      footer={
        errorList.length > 0 ? (
          <Button type="primary" onClick={() => setOpen(false)}>
            知道了
          </Button>
        ) : (
          <Space size={8}>
            <Button onClick={() => setOpen(false)}>取消</Button>
            <Button type="primary" onClick={onFinish}>
              确定
            </Button>
          </Space>
        )
      }
    >
      {errorList.length > 0 ? (
        <>
          <div style={{ marginBottom: 10 }}>
            <CloseCircleOutlined style={{ color: 'var(--mx-error-color)' }} />
            <span style={{ color: 'var(--mx-text-secondary-color)', marginLeft: 4 }}>导入结束，请检查以下问题项目</span>
          </div>
          <Table rowKey="id" columns={columns} dataSource={errorList} loading={loading} />
        </>
      ) : (
        <Form form={form}>
          <Form.Item label="选择文件">
            <Form.Item rules={[{ required: true, message: '请先上传文件' }]} name="fileKey" noStyle>
              <Upload
                action="/api/v2/upload/upload"
                name="file"
                listType="text"
                directory
                beforeUpload={beforeUpload}
                maxCount={10}
                onChange={handleChange}
              >
                <Button icon={<UploadOutlined />}>上传文件</Button>
              </Upload>
            </Form.Item>
            <div style={{ color: 'var(--mx-text-desc-color)', marginTop: 16 }}>仅限上传文件夹</div>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default UploadProve;
