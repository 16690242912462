import { RoutePathItem } from '@maxtropy/components';
import EditInverterBaseInfo from '@/pages/InverterTestManagement/EditBaseInfo';
import ViewInverterBaseInfo from '@/pages/InverterTestManagement/ViewBaseInfo';
import ViewInverterTaskCase from '@/pages/InverterTestManagement/ViewTaskCase';
import ViewComponentBaseInfo from '@/pages/ComponentTestManagement/ViewBaseInfo';
import ViewComponentTaskCase from '@/pages/ComponentTestManagement/ViewTaskCase';
import EditComponentBaseInfo from '@/pages/ComponentTestManagement/EditBaseInfo';
import TestCases from '@/pages/TestCases';
import TestTasks from '@/pages/TestTasks';

import InverterTestCase from '@/pages/InverterTestManagement/TestCase';
import ComponentTestCase from '@/pages/ComponentTestManagement/TestCase';
import AddTestCase from '@/pages/TestCases/AddTestCase';
import ViewTestCases from '@/pages/TestCases/ViewTestCase';
import InverterTestCaseManagement from '@/pages/InverterTestCaseManagement';
import InverterTestCaseAdd from '@/pages/InverterTestCaseManagement/InverterTestCaseAdd';
import InverterTestCaseDetail from '@/pages/InverterTestCaseManagement/InverterTestCaseDetail';
import ViewBaseInfo from '@/pages/TestTasks/ViewBaseInfo';
import ViewTaskCase from '@/pages/TestTasks/ViewTaskCase';
import AddBaseInfo from '@/pages/TestTasks/AddBaseInfo';
import InverterTestManagement from '@/pages/InverterTestManagement';
import ComponentTestCaseManagement from '@/pages/ComponentTestCaseManagement';
import ComponentTestCaseAdd from '@/pages/ComponentTestCaseManagement/ComponentTestCaseAdd';
import ComponentTestManagement from '@/pages/ComponentTestManagement';
import AddTaskCase from '@/pages/TestTasks/AddTaskCase';
import ComponentTestCaseDetail from '@/pages/ComponentTestCaseManagement/ComponentTestCaseDetail';
import ViewFile from '@/pages/TestTasks/ViewFile';
import { PermissionsType } from '@/common/permissionsConst';

const mainRoutes: RoutePathItem[] = [
  {
    path: '/testCases/management/list',
    permission: PermissionsType.P_SOFTWARETESTCASES,
    element: <TestCases />,
  },
  {
    path: '/testCases/management/list/add',
    permission: PermissionsType.P_NEWSOFTWARETESTCASEPAGE,
    element: <AddTestCase />,
  },
  {
    path: '/testCases/management/list/edit/:id',
    permission: PermissionsType.P_EDITSOFTWARETESTCASEPAGE,
    element: <AddTestCase isEdit />,
  },
  {
    path: '/testCases/management/list/view/:id',
    permission: PermissionsType.P_VIEWSOFTWARETESTCASEPAGE,
    element: <ViewTestCases />,
  },
  {
    path: '/testTasks/management/list',
    permission: PermissionsType.P_SOFTWARETESTINGTASKTOOL,
    element: <TestTasks />,
  },
  {
    path: '/testTasks/management/list/addBase',
    permission: PermissionsType.P_NEWBASICSOFTWARETESTINGTASK,
    element: <AddBaseInfo />,
  },
  {
    path: '/testTasks/management/list/editBase/:id',
    permission: PermissionsType.P_EDITBASICSOFTWARETESTINGTASK,
    element: <AddBaseInfo isEdit />,
  },
  {
    path: '/testTasks/management/list/editCase/:id',
    permission: PermissionsType.P_EDITCASESOFTWARETESTINGTASK,
    element: <AddTaskCase isEdit />,
  },
  {
    path: '/testTasks/management/list/viewBase/:id',
    permission: PermissionsType.P_VIEWSOFTWARETESTINGTASK,
    element: <ViewBaseInfo />,
  },
  {
    path: '/testTasks/management/list/viewCase/:id',
    permission: PermissionsType.P_VIEWSOFTWARETESTINGTASK,
    element: <ViewTaskCase />,
  },
  // 组串/组件测试工具管理
  {
    path: '/testTools/componentTest/management',
    permission: PermissionsType.P_STRINGTESTINGTASKTOOL,
    element: <ComponentTestManagement />,
  },
  {
    path: '/testTools/componentTest/management/createBase',
    permission: PermissionsType.P_NEWCASESTRINGTESTTASK,
    element: <EditComponentBaseInfo />,
  },
  {
    path: '/testTools/componentTest/management/editBase/:id',
    permission: PermissionsType.P_EDITBASICSTRINGTESTTASK,
    element: <EditComponentBaseInfo />,
  },
  {
    path: '/testTools/componentTest/management/editTask/:id',
    permission: PermissionsType.P_EDITBASICSTRINGTESTTASK,
    element: <ComponentTestCase />,
  },
  {
    path: '/testTools/componentTest/management/viewBase/:id',
    permission: PermissionsType.P_VIEWSTRINGTESTTASK,
    element: <ViewComponentBaseInfo />,
  },
  {
    path: '/testTools/componentTest/management/viewTask/:id',
    permission: PermissionsType.P_VIEWSTRINGTESTTASK,
    element: <ViewComponentTaskCase />,
  },
  // 逆变器测试工具管理
  {
    path: '/testTools/inverterTest/management',
    permission: PermissionsType.P_INVERTERTESTCASESTASKTOOLS,
    element: <InverterTestManagement />,
  },
  {
    path: '/testTools/inverterTest/management/createBase',
    permission: PermissionsType.P_NEWBASICINVERTERTESTCASESTASKS,
    element: <EditInverterBaseInfo />,
  },
  {
    path: '/testTools/inverterTest/management/editBase/:id',
    permission: PermissionsType.P_EDITBASICINVERTERTESTCASESTASKS,
    element: <EditInverterBaseInfo />,
  },
  {
    path: '/testTools/inverterTest/management/editTask/:id',
    permission: PermissionsType.P_EDITCASESINVERTERTESTCASESTASKS,
    element: <InverterTestCase />,
  },
  {
    path: '/testTools/inverterTest/management/viewBase/:id',
    permission: PermissionsType.P_VIEWINVERTERTESTCASESTASKS,
    element: <ViewInverterBaseInfo />,
  },
  {
    path: '/testTools/inverterTest/management/viewTask/:id',
    permission: PermissionsType.P_VIEWINVERTERTESTCASESTASKS,
    element: <ViewInverterTaskCase />,
  },
  // 逆变器测试用例管理
  {
    path: '/inverter/testCase/management',
    permission: PermissionsType.P_INVERTERTESTCASES,
    element: <InverterTestCaseManagement />,
  },
  // 新建逆变器测试用例
  {
    path: '/inverter/testCase/management/add',
    permission: PermissionsType.P_NEWINVERTERTESTCASES,
    element: <InverterTestCaseAdd />,
  },
  // 编辑逆变器测试用例
  {
    path: '/inverter/testCase/management/edit/:id',
    permission: PermissionsType.P_EDITINVERTERTESTCASES,
    element: <InverterTestCaseAdd />,
  },
  // 查看逆变器测试用例
  {
    path: '/inverter/testCase/management/view/:id',
    permission: PermissionsType.P_VIEWINVERTERTESTCASES,
    element: <InverterTestCaseDetail />,
  },
  // 组串/组件测试用例管理
  {
    path: '/component/testCase/management',
    permission: PermissionsType.P_STRINGTESTCASE,
    element: <ComponentTestCaseManagement />,
  },
  // 新建测试用例
  {
    path: '/component/testCase/management/add',
    permission: PermissionsType.P_NEWSTRINGTESTTASE,
    element: <ComponentTestCaseAdd />,
  },
  // 编辑测试用例
  {
    path: '/component/testCase/management/edit/:id',
    permission: PermissionsType.P_EDITSTRINGTESTTASE,
    element: <ComponentTestCaseAdd />,
  },
  // 查看测试用例
  {
    path: '/component/testCase/management/view/:id',
    permission: PermissionsType.P_VIEWSTRINGTESTTASE,
    element: <ComponentTestCaseDetail />,
  },
  {
    path: '/testTasks/management/viewFile/:id',
    permission: '',
    element: <ViewFile />,
  },
];
export default mainRoutes;
