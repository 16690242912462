import {
  BaseInfoContent,
  Button,
  EllipsisSpan,
  Form,
  FormTitle,
  Paging,
  ShowInput,
  SubContent,
  Table,
  usePaging,
  Wrapper,
} from '@maxtropy/components';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isNil } from 'lodash';
import {
  apiV2TestCaseDetailPost,
  apiV2TestCaseVersionPagePost,
  V2TestCaseDetailPostResponse,
  V2TestCaseVersionPagePostResponse,
} from '@maxtropy/pv-test-apis-v2';
import {
  CaseType,
  CaseTypeDisplay,
  HardwareTypeType,
  HardwareTypeTypeDisplay,
  JudgmentMethod,
  JudgmentMethodDisplay,
  LevelType,
  LevelTypeDisplay,
  ReferentType,
  ReferentTypeDisplay,
} from '../const';
import { Layout, Spin } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import { BreadCrumb } from '@maxtropy/rc-components';

export type standardRequirementItemProps = Exclude<
  V2TestCaseDetailPostResponse['standardRequirement'],
  undefined
>[number];
const columns = [
  {
    title: '等级',
    dataIndex: 'level',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v ? LevelTypeDisplay[v as LevelType] : '--'} />,
  },
  {
    title: '分数',
    dataIndex: 'score',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '等级说明',
    dataIndex: 'desc',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '相对偏差范围',
    dataIndex: 'range',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string, record: standardRequirementItemProps) => (
      <EllipsisSpan
        value={
          (record.symbol ?? '-') +
          (record.rangeFrom ?? '-') +
          '%' +
          '~' +
          (record.symbol ?? '-') +
          (record.rangeTo ?? '-') +
          '%'
        }
      />
    ),
  },
];

const { Content, Sider } = Layout;
export type VersionItem = Exclude<V2TestCaseVersionPagePostResponse['list'], undefined>[number];

const InverterTestCaseDetail = () => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [currentVersion, setCurrentVersion] = useState<VersionItem>(); // 当前版本号
  const pagingInfo = usePaging();
  const { pageOffset, setTotalCount, setPageOffset } = pagingInfo;
  const [openSider, setOpenSider] = useState(true); // 左侧树形toggle

  const { data, loading } = useRequest(
    () =>
      apiV2TestCaseVersionPagePost({
        caseId: id,
        page: pageOffset,
        size: 20,
      }).then(res => {
        setTotalCount(res.total ?? 0);
        setCurrentVersion(res.list?.[0]);
        return res.list ?? [];
      }),
    {
      refreshDeps: [id, pageOffset],
    }
  );

  return (
    <Wrapper breadCrumb={<BreadCrumb/>} className={styles.wrapper}>
      <FormTitle
        title={'查看测试用例'}
        extraContent={<Button onClick={() => navigate(`/inverter/testCase/management`)}>返回</Button>}
      />
      <Layout className={styles.layout}>
        <Sider width={openSider ? 260 : 0} style={{ transition: 'all 0.2s', background: '#232324' }}>
          <div className={styles.leftContent}>
            <div className={styles.leftTitle}>版本记录</div>
            <div>
              {data?.map((item, index) => (
                <div
                  className={`${styles.card} ${currentVersion?.id === item.id ? styles.active : ''}`}
                  onClick={() => setCurrentVersion(item)}
                >
                  <div className={`${styles.circle} ${index > 0 ? styles.line : ''}`}></div>
                  <div>
                    <div className={styles.version}>{item.versionNo} </div>
                    <div className={styles.desc}>最后编辑人：{item.createUser}</div>
                    <div className={styles.desc}>
                      最后编辑时间：
                      {item.createTime ? dayjs(item.createTime as string).format('YYYY-MM-DD HH:mm:ss') : '--'}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <Paging className={styles.page} pagingInfo={pagingInfo} hideCustomTotal showSizeChanger={false} />
          </div>
        </Sider>
        <Content className={styles.content}>
          <div
            className={styles.toggleBtn}
            style={{ left: openSider ? -41 : 0 }}
            onClick={() => setOpenSider(!openSider)}
          >
            {openSider ? <LeftOutlined /> : <RightOutlined />}
          </div>
          <Spin spinning={loading}>
            <Form form={form}>
              <SubContent title="基础信息">
                <BaseInfoContent colNum={3} layout="horizontal">
                  <Form.Item label="用例名称">
                    <ShowInput value={currentVersion?.name} />
                  </Form.Item>
                  <Form.Item label="版本号">
                    <ShowInput value={currentVersion?.versionNo} />
                  </Form.Item>
                  <Form.Item label="用例类型">
                    <ShowInput
                      value={!isNil(currentVersion?.type) ? CaseTypeDisplay[currentVersion?.type as CaseType] : '--'}
                    />
                  </Form.Item>
                  <Form.Item label="硬件类型">
                    <ShowInput
                      value={
                        !isNil(currentVersion?.hardwareType)
                          ? HardwareTypeTypeDisplay[currentVersion?.hardwareType as HardwareTypeType]
                          : '--'
                      }
                    />
                  </Form.Item>
                  <Form.Item label="所属能力域">
                    <ShowInput value={currentVersion?.abilityDomainDesc} />
                  </Form.Item>
                  <Form.Item label="判定方式">
                    <ShowInput
                      value={currentVersion?.judgmentMethods
                        ?.map(c => JudgmentMethodDisplay[c as JudgmentMethod])
                        .join('')}
                    />
                  </Form.Item>
                  {currentVersion?.judgmentMethods?.includes(JudgmentMethod.SYSTEM) && (
                    <>
                      <Form.Item label="测试指标">
                        <ShowInput value={currentVersion?.testIndicatorDesc} />
                      </Form.Item>
                      <Form.Item label="参照对象">
                        <ShowInput value={ReferentTypeDisplay[currentVersion?.referent as ReferentType]} />
                      </Form.Item>
                    </>
                  )}
                </BaseInfoContent>
              </SubContent>
              <SubContent title="标准要求">
                <Table
                  style={{ width: 600 }}
                  dataSource={currentVersion?.standardRequirement}
                  rowKey="key"
                  columns={columns}
                />
              </SubContent>
              <SubContent title="测试步骤">
                <Form.Item>
                  <ShowInput value={currentVersion?.testProcedure} />
                </Form.Item>
              </SubContent>
            </Form>
          </Spin>
        </Content>
      </Layout>
    </Wrapper>
  );
};

export default InverterTestCaseDetail;
