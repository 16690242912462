import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Divider, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { useRequest } from 'ahooks';
import { isEmpty, isNil } from 'lodash';
import {
  Button,
  Dropdown,
  EllipsisSpan,
  Form,
  FormTitle,
  Paging,
  PopConfirm,
  Select,
  SubContent,
  Table,
  Tag,
  usePaging,
  useUpdate,
  Wrapper,
} from '@maxtropy/components';
import {
  apiV2TestCaseHardwareAbilityDomainListPost,
  apiV2TestTaskAssessmentPost,
  apiV2TestTaskCaseLatestInfoPost,
  apiV2TestTaskCasePagePost,
  apiV2TestTaskComputeResultPost,
  apiV2TestTaskDeleteCasePost,
  apiV2TestTaskDetailPost,
  apiV2TestTaskSaveCasePost,
  V2TestCaseHardwareAbilityDomainListPostResponse,
  V2TestTaskCasePagePostResponse,
} from '@maxtropy/pv-test-apis-v2';
import dayjs from 'dayjs';
import SelectCaseModal from './components/SelectedCaseModal';
import {
  AbilityDomainDisplay,
  AbilityDomainType,
  HardwareType,
  JudgmentMethod,
  JudgmentMethodDisplay,
  LevelType,
  LevelTypeDisplay,
  ReferentType,
  ReferentTypeDisplay,
  StatusType,
} from '@/common/util';
import SwitchVersion from './components/SwitchVersion';
import BatchLinkExperiment from './components/BatchLinkExperiment';
import BatchLinkControlGroup from './components/BatchLinkControlGroup';
import BatchSetRangeTime from './components/BatchSetRangeTime';
import UseCaseCalculation from './components/useCaseCalculation';
import styles from './index.module.scss';
import IntelligentEvaluationCriteria from '@/pages/InverterTestManagement/TestCase/components/IntelligentEvaluationCritria';
import UploadAssess from '@/components/UploadAssess';
import UploadProve from '@/components/UploadProve';
import { BreadCrumb } from '@maxtropy/rc-components';

const routes = [{ name: '编辑任务测试用例' }];

export type DataType = Exclude<V2TestTaskCasePagePostResponse['list'], undefined>[number];
export type HardWareAbilityDomainItem = Exclude<
  V2TestCaseHardwareAbilityDomainListPostResponse['list'],
  undefined
>[number];

export const buildColumns: ColumnsType<DataType> = [
  {
    title: '用例编号',
    ellipsis: { showTitle: true },
    dataIndex: 'caseCode',
    render: v => <EllipsisSpan value={v} />,
    width: 150,
    fixed: 'left' as const,
  },
  {
    title: '用例名称',
    ellipsis: { showTitle: true },
    dataIndex: 'name',
    width: 100,
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '所属能力域',
    ellipsis: { showTitle: true },
    dataIndex: 'abilityDomain',
    width: 100,
    render: (v: AbilityDomainType) => <EllipsisSpan value={AbilityDomainDisplay[v]} />,
  },
  {
    title: '测试指标(单位)',
    ellipsis: { showTitle: true },
    dataIndex: 'testIndicatorDesc',
    width: 140,
    render: (v, record) => <EllipsisSpan value={v && `${v}(${record.testIndicatorUnit ?? '-'})`} />,
  },
  {
    title: '参照对象',
    dataIndex: 'referent',
    ellipsis: { showTitle: true },
    width: 100,
    render: (v: ReferentType) => <EllipsisSpan value={ReferentTypeDisplay[v]} />,
  },
  {
    title: '实验组',
    width: 100,
    ellipsis: { showTitle: true },
    dataIndex: 'experimentalGroupDeviceName',
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '对照组',
    width: 100,
    ellipsis: { showTitle: true },
    dataIndex: 'controlGroupDeviceName',
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '理论值',
    width: 100,
    ellipsis: { showTitle: true },
    dataIndex: 'theoreticalValue',
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '分析时段',
    width: 180,
    ellipsis: { showTitle: true },
    dataIndex: 'startTime',
    render: (_, record) => (
      <EllipsisSpan
        value={
          record?.startTime &&
          `${dayjs(String(record?.startTime)).format('YYYY-MM-DD HH:ss:mm')} ~ ${dayjs(String(record.endTime)).format(
            'YYYY-MM-DD HH:ss:mm'
          )}`
        }
      />
    ),
  },
  {
    title: '状态',
    width: 100,
    dataIndex: 'status',
    render: v => (
      <EllipsisSpan
        value={
          v === StatusType.ENABLE ? (
            <Tag border="solid" color="success">
              启用
            </Tag>
          ) : (
            <Tag border="solid" color="default">
              停用
            </Tag>
          )
        }
      />
    ),
  },
  {
    title: '版本',
    width: 100,
    ellipsis: { showTitle: true },
    dataIndex: 'versionNo',
    render: (v, record) => (
      <EllipsisSpan
        value={<div>{!record.latestVersion ? <span style={{ color: 'var(--mx-warning-color)' }}>{v}*</span> : v}</div>}
      />
    ),
  },
  {
    title: '标准要求',
    width: 200,
    dataIndex: 'standardRequirement',
    render: (v, record) => {
      const content = record.standardRequirement?.map(item => {
        return (
          <>
            <span style={{ fontWeight: 'bold' }}>
              {LevelTypeDisplay[item.level as LevelType]}（{item.score}分）
            </span>
            {item.desc ?? ''}；
          </>
        );
      });
      return (
        <EllipsisSpan
          className={styles.overFlowStyle}
          value={isEmpty(record.standardRequirement) ? undefined : content}
        />
      );
    },
  },
  {
    title: '测试步骤',
    width: 180,
    ellipsis: { showTitle: true },
    dataIndex: 'testProcedure',
    render: v => <EllipsisSpan value={v} />,
  },
];

const TestCase = () => {
  const navigate = useNavigate();
  const { id: taskId } = useParams<{ id: string }>();

  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;
  const [update, updateFn] = useUpdate();
  const [selectedId, setSelectedId] = useState<number>();
  const [selectedCaseId, setSelectedCaseId] = useState<number>();
  const [referentType, setReferentType] = useState<ReferentType>();
  const [openCase, setOpenCase] = useState<boolean>();
  const [openSwitchVersion, setOpenSwitchVersion] = useState<boolean>();
  const [openBatchLinkExperiment, setOpenBatchLinkExperiment] = useState<boolean>();
  const [openBatchLinkControlGroup, setOpenBatchLinkControlGroup] = useState<boolean>();
  const [openBatchSetRangeTime, setOpenBatchSetRangeTime] = useState<boolean>();
  const [openUseCaseCalculation, setOpenUseCaseCalculation] = useState<boolean>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>();
  const [openProveModal, setOpenProveModal] = useState<boolean>();
  const [openAssessModal, setOpenAssessModal] = useState<boolean>();
  const [form] = Form.useForm();

  const { data: hardwareAbilityDomainData } = useRequest(
    () =>
      apiV2TestCaseHardwareAbilityDomainListPost({
        taskId,
        page: pageOffset,
        size: pageSize,
      }).then(res => res.list ?? []),
    {
      refreshDeps: [],
    }
  );

  const { data: detailData } = useRequest(
    () =>
      apiV2TestTaskDetailPost({
        id: taskId,
      }),
    {
      refreshDeps: [],
    }
  );

  const { data, loading } = useRequest(
    () =>
      apiV2TestTaskCasePagePost({
        taskId,
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        setTotalCount(res.total ?? 0);
        form.setFieldValue('list', res.list ?? []);
        return res.list || [];
      }),
    {
      refreshDeps: [setTotalCount, pageOffset, pageSize, update],
    }
  );

  const { data: assessmentData } = useRequest(
    () =>
      apiV2TestTaskAssessmentPost({
        id: taskId,
      }),
    {
      refreshDeps: [data, taskId],
    }
  );

  const { data: caseLatestInfo } = useRequest(
    () =>
      apiV2TestTaskCaseLatestInfoPost({
        id: taskId,
      }),
    {
      refreshDeps: [update, taskId],
    }
  );

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedRowKeys(selectedRowKeys as number[]);
    },
  };

  const columns: ColumnsType<DataType> = [
    ...buildColumns,
    {
      title: '判定方式',
      dataIndex: 'judgmentMethod',
      fixed: 'right' as const,
      width: 150,
      render: (v: JudgmentMethod, record, index) => {
        if (isEmpty(record.judgmentMethods)) {
          return '--';
        } else if (record.judgmentMethods?.length === 1) {
          return <EllipsisSpan value={JudgmentMethodDisplay[record.judgmentMethods[0] as JudgmentMethod]} />;
        }
        return (
          <Form.Item name={['list', index, 'judgmentMethod']}>
            <Select
              style={{ width: '100%' }}
              onChange={value => {
                apiV2TestTaskSaveCasePost({ id: record.id, judgmentMethod: value }).then(updateFn);
              }}
              options={record.judgmentMethods?.map(item => ({
                label: JudgmentMethodDisplay[item as JudgmentMethod],
                value: item,
              }))}
            />
          </Form.Item>
        );
      },
    },
    {
      title: '结果',
      dataIndex: 'testResult',
      fixed: 'right' as const,
      width: 150,
      render: (v, record, index) => {
        if (isEmpty(record.standardRequirement)) {
          return '--';
        }

        if (record.judgmentMethod === JudgmentMethod.SYSTEM) {
          const levelObj = record.standardRequirement?.find(item => item.level === v);
          if (!levelObj) {
            return '--';
          }
          return (
            <EllipsisSpan value={`${LevelTypeDisplay[levelObj.level as LevelType]}（${levelObj.score ?? 0}分）`} />
          );
        }

        return (
          <Form.Item name={['list', index, 'testResult']}>
            <Select
              style={{ width: '100%' }}
              onChange={value => {
                apiV2TestTaskSaveCasePost({ id: record.id, testResult: value }).then(updateFn);
              }}
              options={record.standardRequirement?.map(item => ({
                label: `${LevelTypeDisplay[item.level as LevelType]}（${item.score ?? 0}分）`,
                value: item.level,
              }))}
            />
          </Form.Item>
        );
      },
    },
    {
      title: '操作',
      dataIndex: 'operation',
      width: 300,
      fixed: 'right' as const,
      render: (v, record) => (
        <Space size={16}>
          <PopConfirm
            title="确定删除所选用例吗？"
            onConfirm={() => {
              apiV2TestTaskDeleteCasePost({ id: record.id }).then(() => {
                updateFn();
              });
            }}
          >
            <Button type="link">删除</Button>
          </PopConfirm>
          <Button
            type="link"
            onClick={() => {
              setSelectedId(record.id);
              setSelectedCaseId(record.caseId);
              setOpenSwitchVersion(true);
            }}
          >
            切换版本
          </Button>
          {record.judgmentMethods &&
            (record.judgmentMethods.length == 1 || record.judgmentMethod === JudgmentMethod.ARTIFICIAL) && (
              <Button
                type="link"
                disabled={isNil(record.fileKeys)}
                onClick={() => navigate(`/testTasks/management/viewFile/${record.id}`)}
              >
                查看资料
              </Button>
            )}
          {record.judgmentMethod === JudgmentMethod.SYSTEM && (
            <>
              <Button
                type="link"
                onClick={() => {
                  setSelectedId(record.id);
                  setReferentType(record.referent);
                  setOpenUseCaseCalculation(true);
                }}
              >
                计算设置
              </Button>
              <Button
                type="link"
                disabled={
                  (record.referent === ReferentType.CONTROL_GROUP && !record.controlGroupDeviceName) ||
                  (record.referent === ReferentType.THEORETICAL_VALUE && !record.theoreticalValue) ||
                  !record.startTime
                }
                onClick={() => {
                  apiV2TestTaskComputeResultPost({ id: record.id }).then(() => updateFn());
                }}
              >
                执行
              </Button>
              <Button type="link" disabled={!record.testResult} href={`/api/v2/test-task/downloadData?id=${record.id}`}>
                下载数据
              </Button>
            </>
          )}
        </Space>
      ),
    },
  ];

  const itemsMenu = [
    {
      key: '1',
      label: '导出自评表',
      onClick: () => {
        window.open(`/api/v2/test-task/downloadSelfEvaluationForm?id=${taskId}`);
      },
    },
    {
      key: '2',
      label: '导入自评结果',
      onClick: () => setOpenAssessModal(true),
    },
    {
      key: '3',
      label: '导入证明材料',
      onClick: () => setOpenProveModal(true),
    },
  ];

  return (
    <Wrapper breadCrumb={<BreadCrumb options={routes} />}>
      <Form isOrigin={true} form={form} layout="horizontal">
        <FormTitle title="编辑任务测试用例" />
        <SubContent>
          <div className={styles.taskInfo}>
            <Space
              size={8}
              align="center"
              split={<Divider type="vertical" style={{ borderColor: 'rgba(255,255,255,0.2)' }} />}
            >
              <Form.Item label="任务名称">{detailData?.name ?? '--'}</Form.Item>
              <Form.Item label="任务类型">硬件测试</Form.Item>
              <Form.Item label="硬件类型">组串/组件</Form.Item>
              <Form.Item label="样品名称">{detailData?.sampleName ?? '--'}</Form.Item>
            </Space>
          </div>
          <div className={styles.caseTable}>
            <Space size={10} style={{ marginBottom: 10 }}>
              <Button type="primary" icon={<PlusOutlined />} onClick={() => setOpenCase(true)}>
                选择用例
              </Button>
              <Button
                type="primary"
                disabled={isEmpty(selectedRowKeys)}
                onClick={() => setOpenBatchLinkExperiment(true)}
              >
                批量设置实验组
              </Button>
              <Button
                type="primary"
                disabled={isEmpty(selectedRowKeys)}
                onClick={() => setOpenBatchLinkControlGroup(true)}
              >
                批量设置对照组
              </Button>
              <Button type="primary" disabled={isEmpty(selectedRowKeys)} onClick={() => setOpenBatchSetRangeTime(true)}>
                批量设置分析时段
              </Button>
              <Dropdown
                menu={{
                  items: itemsMenu,
                }}
                type="primary"
              >
                更多
              </Dropdown>
              <div>
                <span style={{ color: 'var(--mx-error-color)', marginRight: 4 }}>
                  {caseLatestInfo?.stoppedCount ?? 0}
                </span>
                条用例已停用，
                <span style={{ color: 'var(--mx-warning-color)', marginRight: 4 }}>
                  {caseLatestInfo?.updatingCount ?? 0}
                </span>
                条用例版本有更新（对应用例版本号已标星<span style={{ color: 'var(--mx-warning-color)' }}>*</span> ）
              </div>
            </Space>
            <Form.Item name="list" valuePropName="dataSource">
              <Table
                rowSelection={{
                  ...rowSelection,
                }}
                rowKey="id"
                columns={columns}
                // dataSource={data}
                loading={loading}
                scroll={{ x: 600 }}
              />
            </Form.Item>
            <Paging pagingInfo={pagingInfo} />
          </div>
          {/* 测试结果 */}
          <IntelligentEvaluationCriteria data={assessmentData} type={HardwareType.Component} />
          <Space size={8} className={'sticky-footer-left'}>
            <Button wrapStyle={{ marginTop: 32 }} onClick={() => navigate(-1)}>
              返回
            </Button>
          </Space>
        </SubContent>
      </Form>
      {openCase && (
        <SelectCaseModal
          taskId={taskId}
          abilityDomainData={hardwareAbilityDomainData}
          onCancel={() => setOpenCase(false)}
          updateFn={updateFn}
        />
      )}
      {openSwitchVersion && (
        <SwitchVersion
          caseId={selectedCaseId}
          id={selectedId}
          onCancel={() => setOpenSwitchVersion(false)}
          updateFn={updateFn}
        />
      )}
      {openBatchLinkExperiment && (
        <BatchLinkExperiment
          ids={selectedRowKeys}
          onCancel={() => setOpenBatchLinkExperiment(false)}
          updateFn={updateFn}
        />
      )}
      {openBatchLinkControlGroup && (
        <BatchLinkControlGroup
          ids={selectedRowKeys}
          onCancel={() => setOpenBatchLinkControlGroup(false)}
          updateFn={updateFn}
        />
      )}
      {openBatchSetRangeTime && (
        <BatchSetRangeTime ids={selectedRowKeys} onCancel={() => setOpenBatchSetRangeTime(false)} updateFn={updateFn} />
      )}
      {openUseCaseCalculation && (
        <UseCaseCalculation
          id={selectedId}
          referent={referentType}
          onCancel={() => setOpenUseCaseCalculation(false)}
          updateFn={updateFn}
        />
      )}
      {openProveModal && <UploadProve taskId={taskId} setOpen={setOpenProveModal} updateFn={updateFn} />}
      {openAssessModal && <UploadAssess taskId={taskId} setOpen={setOpenAssessModal} updateFn={updateFn} />}
    </Wrapper>
  );
};

export default TestCase;
