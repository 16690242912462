import {
  CustomFilter,
  EllipsisSpan,
  Form,
  message,
  Modal,
  Paging,
  Select,
  Table,
  usePaging,
} from '@maxtropy/components';
import React, { useState } from 'react';
import {
  apiV2TestCaseVersionListPost,
  apiV2TestCaseVersionPagePost,
  apiV2TestTaskSaveCasePost,
  V2TestCaseVersionPagePostRequest,
  V2TestCaseVersionPagePostResponse,
} from '@maxtropy/pv-test-apis-v2';
import { useRequest } from 'ahooks';
import { isEmpty } from 'lodash';
import { ColumnsType } from 'antd/es/table';
import {
  AbilityDomainDisplay,
  AbilityDomainType,
  LevelType,
  LevelTypeDisplay,
  ReferentType,
  ReferentTypeDisplay,
} from '@/common/util';
import styles from '../index.module.scss';

interface SelectCaseModalProp {
  onCancel: () => void;
  updateFn: () => void;
  caseId?: number;
  id?: number;
}

interface SearchParams extends Omit<V2TestCaseVersionPagePostRequest, 'page' | 'size'> {}
type DataType = Exclude<V2TestCaseVersionPagePostResponse['list'], undefined>[number];

const columns: ColumnsType<DataType> = [
  {
    title: '版本号',
    dataIndex: 'versionNo',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '用例名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '所属能力域',
    dataIndex: 'abilityDomain',
    ellipsis: { showTitle: true },
    render: (v: AbilityDomainType) => <EllipsisSpan value={AbilityDomainDisplay[v]} />,
  },
  {
    title: '测试指标',
    dataIndex: 'testIndicatorDesc',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '参照对象',
    dataIndex: 'referent',
    ellipsis: { showTitle: true },
    render: (v: ReferentType) => <EllipsisSpan value={ReferentTypeDisplay[v]} />,
  },
  {
    title: '标准要求',
    dataIndex: 'standardRequirement',
    width: 200,
    render: (v, record) => {
      const content = record.standardRequirement?.map(item => {
        return (
          <>
            <span style={{ fontWeight: 'bold' }}>
              {LevelTypeDisplay[item.level as LevelType]}（{item.score}分）
            </span>
            {item.desc ?? ''}；
          </>
        );
      });
      return (
        <EllipsisSpan
          className={styles.overFlowStyle}
          value={isEmpty(record.standardRequirement) ? undefined : content}
        />
      );
    },
  },
  {
    title: '测试步骤',
    dataIndex: 'testProcedure',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan className={styles.overFlowStyle} value={v} />,
  },
];

const SwitchVersion = ({ onCancel, updateFn, caseId, id }: SelectCaseModalProp) => {
  const [form] = Form.useForm();
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<SearchParams>({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>();

  const { data: versionData } = useRequest(
    () =>
      apiV2TestCaseVersionListPost({
        caseId,
      }).then(res => res.list ?? []),
    {
      refreshDeps: [],
    }
  );

  const { data, loading } = useRequest(
    () =>
      apiV2TestCaseVersionPagePost({
        ...searchParams,
        caseId,
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        setTotalCount(res.total ?? 0);
        return res.list || [];
      }),
    {
      refreshDeps: [searchParams, setTotalCount],
    }
  );

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedRowKeys(selectedRowKeys as number[]);
    },
  };

  const onOk = () => {
    if (selectedRowKeys && selectedRowKeys?.length > 0) {
      apiV2TestTaskSaveCasePost({
        id: id!,
        caseVersionId: selectedRowKeys[0],
      }).then(() => {
        onCancel();
        updateFn();
      });
    } else {
      message.warning('请选择版本');
    }
  };

  const onFinish = (v: SearchParams) => {
    setSearchParams(v);
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({});
    setPageOffset(1);
  };

  const filters = (
    <CustomFilter<SearchParams> form={form} onFinish={onFinish} onReset={onReset} colSpan={8}>
      <Form.Item name="versionId" label="版本号">
        <Select
          placeholder="请选择"
          options={versionData?.map(item => ({ label: item.versionNo, value: item.versionId }))}
        />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Modal title="切换版本" size="big" bodyScroll open={true} onCancel={onCancel} onOk={onOk}>
      {filters}
      <Form>
        <div>
          <Table
            rowSelection={{
              type: 'radio',
              ...rowSelection,
            }}
            rowKey="id"
            columns={columns}
            dataSource={data}
            loading={loading}
          />
          <Paging pagingInfo={pagingInfo} />
        </div>
      </Form>
    </Modal>
  );
};

export default SwitchVersion;
