import {
  AbilityDomainDisplay,
  AbilityDomainType,
  CaseType,
  CaseTypeDisplay,
  JudgmentMethod,
  JudgmentMethodDisplay,
  ReferentType,
  ReferentTypeDisplay,
} from '@/common/util';
import {
  HardwareTypeType,
  HardwareTypeTypeDisplay,
  LevelType,
  LevelTypeDisplay,
} from '@/pages/InverterTestCaseManagement/const';
import {
  Button,
  Checkbox,
  EllipsisSpan,
  Form,
  FormTitle,
  Input,
  PopConfirm,
  Radio,
  Select,
  ShowInput,
  SubContent,
  Table,
  Wrapper,
} from '@maxtropy/components';
import {
  apiV2TestCaseCreatePost,
  apiV2TestCaseDetailPost,
  apiV2TestCaseIndicatorListPost,
  apiV2TestCaseUpdatePost,
} from '@maxtropy/pv-test-apis-v2';
import { useRequest } from 'ahooks';
import { Col, Row, Space, Spin } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditLevelDescModal from './EditLevelDescModal';
import { isNil } from 'lodash';
import { BreadCrumb } from '@maxtropy/rc-components';

const defaultTableData: DataType[] = [
  {
    level: LevelType.D,
    score: 0,
    desc: undefined,
    symbol: undefined,
    rangeFrom: undefined,
    rangeTo: undefined,
  },
  {
    level: LevelType.C,
    score: 1,
    desc: undefined,
    symbol: undefined,
    rangeFrom: undefined,
    rangeTo: undefined,
  },
  {
    level: LevelType.B,
    score: 2,
    desc: undefined,
    symbol: undefined,
    rangeFrom: undefined,
    rangeTo: undefined,
  },
  {
    level: LevelType.A,
    score: 3,
    desc: undefined,
    symbol: undefined,
    rangeFrom: undefined,
    rangeTo: undefined,
  },
];

export interface DataType {
  level?: LevelType;
  score?: number;
  desc?: string;
  symbol?: string;
  rangeFrom?: number;
  rangeTo?: number;
}

const columns = [
  {
    title: '等级',
    dataIndex: 'level',
    width: 60,
    ellipsis: { showTitle: true },
    render: (v: LevelType) => <EllipsisSpan value={v ? LevelTypeDisplay[v] : '--'} />,
  },
  {
    title: '分数',
    dataIndex: 'score',
    width: 60,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '等级说明',
    dataIndex: 'desc',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '相对偏差范围',
    dataIndex: 'range',
    width: 140,
    ellipsis: { showTitle: true },
    render: (v: string, record: DataType) => (
      <EllipsisSpan
        value={
          !isNil(record.symbol)
            ? record.symbol + record.rangeFrom + '%' + '~' + record.symbol + record.rangeTo + '%'
            : '--'
        }
      />
    ),
  },
];

const ComponentTestCaseAdd = () => {
  const { id: caseId } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState<DataType[]>(defaultTableData); // 表格数据
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const judgmentMethods: number[] = Form.useWatch('judgmentMethods', form);
  const testIndicator: number = Form.useWatch('testIndicator', form);
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState<DataType[]>([]);
  const [currentRow, setCurrentRow] = useState<DataType>(); // 标准要求当前选中的行
  const [loading, setLoading] = useState<boolean>(false);

  const routes = useMemo(() => {
    return [{ name: `${caseId ? '编辑' : '新建'}测试用例` }];
  }, [caseId]);

  useEffect(() => {
    if (isNil(caseId)) return;
    setLoading(true);
    apiV2TestCaseDetailPost({ id: Number(caseId) })
      .then(res => {
        form.setFieldsValue({
          ...res,
        });
        const list = tableData.map(m => {
          const prevRow = res.standardRequirement?.find(k => k.level === m.level);
          return {
            ...m,
            level: prevRow?.level ?? m.level,
            score: prevRow?.score ?? m.score,
            desc: prevRow?.desc ?? m.desc,
            symbol: prevRow?.symbol ?? m.symbol,
            rangeFrom: prevRow?.rangeFrom ?? m.rangeFrom,
            rangeTo: prevRow?.rangeTo ?? m.rangeTo,
          };
        });
        setSelectedRows(res.standardRequirement ?? []);
        setTableData([...list]);
      })
      .finally(() => setLoading(false));
  }, [caseId]);

  // 测试指标
  const { data: indicatorList } = useRequest(async () => {
    const res = await apiV2TestCaseIndicatorListPost({
      type: HardwareTypeType.STRING,
    });
    return res.list ?? [];
  });

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 180,
      fixed: 'right' as 'right',
      render: (value: undefined, record: any) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => {
                setCurrentRow(record);
                setModalOpen(true);
              }}
            >
              编辑等级说明
            </Button>
          </Space>
        );
      },
    },
  ];

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      const params = {
        ...values,
        type: CaseType.HARDWARE,
        hardwareType: HardwareTypeType.STRING,
        standardRequirement: selectedRows.map(m => tableData.find(c => c.level === m.level)),
      };
      if (isNil(caseId)) {
        apiV2TestCaseCreatePost(params).then(_ => {
          navigate(`/component/testCase/management`);
        });
      } else {
        apiV2TestCaseUpdatePost({ id: caseId, ...params }).then(_ => {
          navigate(`/component/testCase/management`);
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedRows(selectedRows);
    },
    selectedRowKeys: (selectedRows ?? []).map(i => i.level!),
  };

  return (
    <Wrapper breadCrumb={<BreadCrumb options={routes} />}>
      <FormTitle title={routes[0].name} />
      <Spin spinning={loading}>
        <Form
          form={form}
          initialValues={{
            type: CaseType.HARDWARE,
            judgmentMethods: [JudgmentMethod.ARTIFICIAL],
            abilityDomain: AbilityDomainType.HARDWARE_PERFORMANCE,
          }}
        >
          <SubContent title="基础信息">
            <Row>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label="用例名称"
                  rules={[
                    { required: true, message: '请输入用例名称' },
                    { max: 100, message: '不超过100个字符' },
                  ]}
                >
                  <Input placeholder="请输入" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Form.Item label="用例类型">
                  <ShowInput value={CaseTypeDisplay[CaseType.HARDWARE]} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item label="硬件类型">
                  <ShowInput value={HardwareTypeTypeDisplay[HardwareTypeType.STRING]} />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Form.Item name="abilityDomain" label="所属能力域" rules={[{ required: true, message: '请选择' }]}>
                  <Radio.Group>
                    <Radio value={AbilityDomainType.HARDWARE_PERFORMANCE}>
                      {AbilityDomainDisplay[AbilityDomainType.HARDWARE_PERFORMANCE]}
                    </Radio>
                    <Radio value={AbilityDomainType.INTELLIGENT_CONTROL}>
                      {AbilityDomainDisplay[AbilityDomainType.INTELLIGENT_CONTROL]}
                    </Radio>
                    <Radio value={AbilityDomainType.DATA_TRANSMISSION}>
                      {AbilityDomainDisplay[AbilityDomainType.DATA_TRANSMISSION]}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item name="judgmentMethods" label="判定方式" rules={[{ required: true, message: '请选择' }]}>
                  <Checkbox.Group>
                    <Checkbox disabled value={JudgmentMethod.ARTIFICIAL}>
                      {JudgmentMethodDisplay[JudgmentMethod.ARTIFICIAL]}
                    </Checkbox>
                    <Checkbox value={JudgmentMethod.SYSTEM}>{JudgmentMethodDisplay[JudgmentMethod.SYSTEM]}</Checkbox>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>
            {judgmentMethods?.includes(JudgmentMethod.SYSTEM) && (
              <>
                <Row>
                  <Col span={24}>
                    <Form.Item name="testIndicator" label="测试指标" rules={[{ required: true, message: '请选择' }]}>
                      <Select
                        options={(indicatorList ?? []).map(item => ({
                          label: item.name,
                          value: item.id,
                        }))}
                        placeholder="请选择"
                        onChange={() => form.setFieldValue('referent', undefined)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name="referent" label="参照对象" rules={[{ required: true, message: '请选择' }]}>
                      <Select
                        options={
                          testIndicator === 6
                            ? Object.entries(ReferentTypeDisplay)
                                .map(([k, v]) => ({
                                  label: v,
                                  value: Number(k),
                                }))
                                .filter(i => i.value !== ReferentType.CONTROL_GROUP)
                            : Object.entries(ReferentTypeDisplay).map(([k, v]) => ({
                                label: v,
                                value: Number(k),
                              }))
                        }
                        placeholder="请选择"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </SubContent>
          <SubContent title="标准要求">
            <Table
              style={{ width: 600 }}
              dataSource={tableData}
              rowKey="level"
              columns={buildColumns}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
            />
          </SubContent>
          <SubContent title="测试步骤">
            <Form.Item name="testProcedure" rules={[{ max: 500, message: '不超过500个字符' }]}>
              <Input.TextArea style={{ width: 600 }} placeholder="请输入" />
            </Form.Item>
          </SubContent>
        </Form>
      </Spin>
      <Space size={8} style={{ marginLeft: 32 }} className={'sticky-footer-left'}>
        <PopConfirm placement="top" title="确定根据当前信息创建新的用例版本吗？" onConfirm={onFinish}>
          <Button type="primary">保存</Button>
        </PopConfirm>
        <Button onClick={() => navigate(`/component/testCase/management`)}>取消</Button>
      </Space>
      <EditLevelDescModal
        modalOpen={modalOpen}
        setModalOpen={val => setModalOpen(val)}
        isSystem={judgmentMethods?.includes(JudgmentMethod.SYSTEM)}
        preRow={currentRow}
        onSubmit={values => {
          setTableData(tableData.map(m => (m.level === currentRow?.level ? { ...currentRow, ...values } : m)));
        }}
      />
    </Wrapper>
  );
};

export default ComponentTestCaseAdd;
