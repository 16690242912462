import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isNil } from 'lodash';
import dayjs from 'dayjs';
import {
  Button,
  Wrapper,
  Form,
  FormTitle,
  SubContent,
  ShowInput,
  BaseInfoContent,
} from '@maxtropy/components';
import { V2TestTaskDetailPostResponse, apiV2TestTaskDetailPost } from '@maxtropy/pv-test-apis-v2';
import { BreadCrumb } from '@maxtropy/rc-components';

const ViewBaseInfo: React.FC = () => {
  const { id: taskId } = useParams<{ id: string }>();
  const [detail, setDetail] = useState<V2TestTaskDetailPostResponse>();
  const navigate = useNavigate();

  useEffect(() => {
    if (isNil(taskId)) return;
    apiV2TestTaskDetailPost({ id: taskId }).then(res => {
      setDetail(res);
    });
  }, [taskId]);

  return (
    <Wrapper breadCrumb={<BreadCrumb options={[{ name: '查看基础信息' }]}/>}>
      <Form>
        <FormTitle title="查看基础信息" />
        <SubContent>
          <BaseInfoContent colNum={3} layout="horizontal">
            <Form.Item label="任务名称">
              <ShowInput value={detail?.name} />
            </Form.Item>
            <Form.Item label="任务类型">
              <ShowInput value={'硬件测试'} />
            </Form.Item>
            <Form.Item label="硬件类型">
              <ShowInput value={'逆变器'} />
            </Form.Item>
            <Form.Item label="样品名称">
              <ShowInput value={detail?.sampleName} />
            </Form.Item>
            <Form.Item label="版本号">
              <ShowInput value={detail?.versionNo} />
            </Form.Item>
            <Form.Item label="样品来源">
              <ShowInput value={detail?.sampleSource} />
            </Form.Item>
            <Form.Item label="送检日期">
              <ShowInput value={dayjs(String(detail?.submissionDate)).format('YYYY-MM-DD')} />
            </Form.Item>
            <Form.Item label="申请人">
              <ShowInput value={detail?.applyPerson} />
            </Form.Item>
            <Form.Item label="申请人地址">
              <ShowInput value={detail?.applyPersonAddress} />
            </Form.Item>
            <Form.Item label="制造商">
              <ShowInput value={detail?.manufacture} />
            </Form.Item>
            <Form.Item label="制造商地址">
              <ShowInput value={detail?.manufactureAddress} />
            </Form.Item>
            <Form.Item label="测试单位">
              <ShowInput value={detail?.testEnterprise} />
            </Form.Item>
            <Form.Item label="测试地点">
              <ShowInput value={detail?.testPlace} />
            </Form.Item>
          </BaseInfoContent>
          <Button wrapStyle={{ marginTop: 32 }} onClick={() => navigate(-1)}>
            返回
          </Button>
        </SubContent>
      </Form>
    </Wrapper>
  );
};

export default ViewBaseInfo;
