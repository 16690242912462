import { DatePicker, Form, Modal } from '@maxtropy/components';
import { apiV2TestTaskBatchSetRangeTimePost } from '@maxtropy/pv-test-apis-v2';
import dayjs from 'dayjs';
import { disabled31DaysDate } from '@/pages/InverterTestManagement/TestCase/components/BatchSetRangeTime';

interface BatchSetRangeTimeProp {
  onCancel: () => void;
  updateFn: () => void;
  ids?: number[];
}

const BatchSetRangeTime = ({ onCancel, updateFn, ids }: BatchSetRangeTimeProp) => {
  const [form] = Form.useForm();

  const onOk = () => {
    form.validateFields().then(values => {
      apiV2TestTaskBatchSetRangeTimePost(
        {
          startTime: dayjs(values.analysisPeriod[0]).valueOf(),
          endTime: dayjs(values.analysisPeriod[1]).valueOf(),
          ids,
        }
      ).then(() => {
        onCancel();
        updateFn();
      });
    });
  };

  return (
    <Modal title="批量设置分析时段" open={true} bodyScroll onCancel={onCancel} onOk={onOk}>
      <Form form={form} style={{ padding: '0 48px' }}>
        <Form.Item label="分析时段" rules={[{ required: true, message: '请选择时间段' }]} name="analysisPeriod">
          <DatePicker.RangePicker showTime  disabledDate={disabled31DaysDate}/>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BatchSetRangeTime;
