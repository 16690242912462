import { CustomFilter, EllipsisSpan, Form, Input, Modal, Paging, Select, Table, usePaging } from '@maxtropy/components';
import React, { Key, useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import {
  apiV2TestCasePagePost,
  apiV2TestCaseSoftwareAbilityDomainListPost,
  apiV2TestTaskBatchLinkCasePost,
  V2TestCasePagePostRequest,
  V2TestCasePagePostResponse,
} from '@maxtropy/pv-test-apis-v2';
import { ColumnsType } from 'antd/es/table';
import { Cascader } from 'antd';
import { CaseType, IntelligentLevel, IntelligentLevelDisplay, intelligentLevelOptions } from '@/common/util';
import { isNil } from 'lodash';
import { useParams } from 'react-router-dom';

interface SelectCaseModalProp {
  onCancel: () => void;
  updateFn: () => void;
}

interface SearchParams extends Omit<V2TestCasePagePostRequest, 'page' | 'size'> {}
type DataType = Exclude<V2TestCasePagePostResponse['list'], undefined>[number];

const baseColumns: ColumnsType<DataType> = [
  {
    title: '用例编号',
    dataIndex: 'code',
    render: v => <EllipsisSpan value={v} />,
    width: 150,
  },
  {
    title: '用例名称',
    dataIndex: 'name',
    width: 100,
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '所属能力域',
    dataIndex: 'abilityDomainDesc',
    width: 100,
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '所属智能级',
    dataIndex: 'intelligentLevel',
    width: 140,
    render: (v, record) => <EllipsisSpan value={IntelligentLevelDisplay[v as IntelligentLevel]} />,
  },
];

const SelectCaseModal = ({ onCancel, updateFn }: SelectCaseModalProp) => {
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [form] = Form.useForm();
  const { id: taskId } = useParams<{ id: string }>();

  const [searchParams, setSearchParams] = useState<SearchParams>({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>();
  const [tableData, setTableData] = useState<any[]>([]);

  const { data: softwareAbilityDomainData } = useRequest(
    () => apiV2TestCaseSoftwareAbilityDomainListPost().then(res => res.list ?? []),
    {
      refreshDeps: [],
    }
  );

  const { data, loading } = useRequest(
    () =>
      apiV2TestCasePagePost({
        ...searchParams,
        page: pageOffset,
        size: pageSize,
        type: CaseType.SOFTWARE,
        taskId: Number(taskId),
      }).then(res => {
        setTotalCount(res.total ?? 0);
        return res.list || [];
      }),

    {
      refreshDeps: [searchParams, setTotalCount],
    }
  );

  const findNamesByAbilityId = (list: any[], abilityId: number) => {
    for (const item of list) {
      for (const ability of item.abilityList) {
        if (ability.id === abilityId) {
          return `${item.name}/${ability.name}`;
        }
      }
    }
    return null; // 如果未找到，返回 null
  };

  const findIdsByAbilityId = (list: any[], abilityId: number) => {
    for (const item of list) {
      if (item.id === abilityId) {
        return item.abilityList.map((k: any) => k.id);
      }
    }
    return null; // 如果未找到，返回 null
  };

  useEffect(() => {
    if (isNil(data) || isNil(softwareAbilityDomainData)) return;

    const list = data.map(m => ({
      ...m,
      abilityDomainDisplay: findNamesByAbilityId(softwareAbilityDomainData, m.abilityDomain!),
    }));
    setTableData(list);
  }, [softwareAbilityDomainData, data]);

  const rowSelection = {
    onChange: (selectedRowKeys: Key[], selectedRows: DataType[]) => {
      setSelectedRowKeys(selectedRowKeys as number[]);
    },
  };

  const onOk = () => {
    apiV2TestTaskBatchLinkCasePost({
      taskId: Number(taskId),
      ids: selectedRowKeys,
    }).then(() => {
      onCancel();
      updateFn();
    });
  };

  const onFinish = (v: any) => {
    const { abilityDomain } = v;

    if (!abilityDomain || abilityDomain.length === 0) {
      setSearchParams({
        ...v,
        name: v.name === '' ? undefined : v.name,
        abilityDomain: undefined,
      });
    }
    if (abilityDomain && abilityDomain.length > 0) {
      const abilityDomainIds = abilityDomain
        .map((m: number[]) => {
          if (m.length === 1) {
            return findIdsByAbilityId(softwareAbilityDomainData!, m[0]);
          }
          return m[1];
        })
        .flat();
      setSearchParams({
        ...v,
        name: v.name === '' ? undefined : v.name,
        abilityDomain: abilityDomainIds,
      });
    }

    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({});
    setPageOffset(1);
  };

  const filters = (
    <CustomFilter<SearchParams> form={form} onFinish={onFinish} onReset={onReset} colSpan={8}>
      <Form.Item name="name" label="名称/编号">
        <Input placeholder={'请输入编号或名称查询'} />
      </Form.Item>
      <Form.Item name="abilityDomain" label="所属能力域">
        <Cascader
          options={softwareAbilityDomainData}
          multiple
          fieldNames={{ label: 'name', value: 'id', children: 'abilityList' }}
          placeholder="请选择"
        />
      </Form.Item>
      <Form.Item name="intelligentLevel" label="所属智能级">
        <Select mode="multiple" options={intelligentLevelOptions} placeholder="请选择" />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Modal title="选择用例" size="big" open={true} onCancel={onCancel} onOk={onOk}>
      {filters}
      <Form>
        <div>
          <Table
            rowSelection={{
              ...rowSelection,
            }}
            rowKey="id"
            columns={baseColumns as ColumnsType<DataType>}
            dataSource={tableData}
            loading={loading}
          />
          <Paging pagingInfo={pagingInfo} />
        </div>
      </Form>
    </Modal>
  );
};

export default SelectCaseModal;
