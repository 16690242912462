import { DeviationType, DeviationTypeDisplay } from '@/pages/InverterTestCaseManagement/const';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Input, InputNumber, message, Modal, Select } from '@maxtropy/components';
import React, { FC, useEffect } from 'react';
import styles from './index.module.scss';
import { DataType } from '.';

export interface IEditLevelDescModal {
  modalOpen: boolean;
  setModalOpen: (val: boolean) => void;
  isSystem: boolean;
  preRow?: DataType;
  onSubmit?: (values: any) => void;
}

const EditLevelDescModal: FC<IEditLevelDescModal> = props => {
  const { modalOpen, setModalOpen, isSystem, preRow, onSubmit } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...preRow,
    });
  }, [preRow]);

  const onOk = () => {
    form
      .validateFields()
      .then(() => {
        const values = form.getFieldsValue();
        if (values.symbol === DeviationTypeDisplay[DeviationType.MINUS]) {
          if (values.rangeTo >= values.rangeFrom) {
            message.error('后面数值需大于前面数值');
          } else {
            onSubmit?.(values);
            setModalOpen(false);
          }
        } else {
          if (values.rangeFrom >= values.rangeTo) {
            message.error('后面数值需大于前面数值');
          } else {
            onSubmit?.(values);
            setModalOpen(false);
          }
        }
      })
      .catch(() => {});
  };

  return (
    <>
      <Modal
        open={modalOpen}
        contentClassName="modal-form-content"
        title={'编辑等级说明'}
        destroyOnClose
        onCancel={() => setModalOpen(false)}
        onOk={onOk}
      >
        <Form form={form} labelCol={{ span: 7 }} labelAlign="left">
          <Form.Item
            name="desc"
            label="等级说明"
            rules={[
              { required: true, message: '请输入等级说明' },
              { max: 100, message: '不超过100个字符' },
            ]}
          >
            <Input.TextArea style={{ width: 600 }} placeholder="请输入" />
          </Form.Item>
          {isSystem && (
            <Form.Item
              label={<span className={styles.required}>相对偏差范围</span>}
              tooltip={{
                title: '左闭右开',
                icon: <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />,
              }}
              style={{ marginBottom: 0 }}
            >
              <Form.Item
                name="symbol"
                rules={[{ required: true, message: '请选择' }]}
                style={{ display: 'inline-block', width: '15%', marginRight: 10 }}
              >
                <Select
                  options={Object.entries(DeviationTypeDisplay).map(([k, v]) => ({
                    label: v,
                    value: v,
                  }))}
                />
              </Form.Item>
              <Form.Item
                name="rangeFrom"
                rules={[{ required: true, message: '请输入' }]}
                style={{ display: 'inline-block', width: '35%', marginRight: 10 }}
              >
                <InputNumber min={0} max={100} precision={2} placeholder="请输入" addonAfter="%" />
              </Form.Item>
              <span className={styles.rangeIcon}>~</span>
              <Form.Item
                name="rangeTo"
                rules={[{ required: true, message: '请输入' }]}
                style={{ display: 'inline-block', width: '35%', marginLeft: 10 }}
              >
                <InputNumber min={0} max={100} precision={2} placeholder="请输入" addonAfter="%" />
              </Form.Item>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default EditLevelDescModal;
