import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  CustomFilter,
  EllipsisSpan,
  Form,
  Input,
  Modal,
  Paging,
  Select,
  Table,
  Tag,
  useSearchParams,
  useUpdate,
  Wrapper,
} from '@maxtropy/components';
import { Space } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  AbilityDomainDisplay,
  AbilityDomainType,
  CaseType,
  CaseTypeDisplay,
  HardwareTypeType,
  HardwareTypeTypeDisplay,
  JudgmentMethod,
  JudgmentMethodDisplay,
  StatusDisplay,
  StatusType,
} from './const';
import { useNavigate } from 'react-router-dom';
import { useRequest } from 'ahooks';
import {
  apiV2TestCaseDeletePost,
  apiV2TestCasePagePost,
  apiV2TestCaseSwitchStartOrStopPost,
  V2TestCasePagePostRequest,
  V2TestCasePagePostResponse,
} from '@maxtropy/pv-test-apis-v2';
import dayjs from 'dayjs';
import { useHasPermission } from '@/common/util';
import { PermissionsType } from '@/common/permissionsConst';
import { BreadCrumb } from '@maxtropy/rc-components';
import { isNil } from 'lodash';

interface SearchParams extends Omit<V2TestCasePagePostRequest, 'page' | 'size'> {}
export type TestCasePageItemProps = Exclude<V2TestCasePagePostResponse['list'], undefined>[number];

const columns = [
  {
    title: '用例编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '用例名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '用例类型',
    dataIndex: 'type',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v ? CaseTypeDisplay[v as CaseType] : '--'} />,
  },
  {
    title: '硬件类型',
    dataIndex: 'hardwareType',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v ? HardwareTypeTypeDisplay[v as HardwareTypeType] : '--'} />,
  },
  {
    title: '所属能力域',
    dataIndex: 'abilityDomain',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v ? AbilityDomainDisplay[v as AbilityDomainType] : '--'} />,
  },
  {
    title: '判定方式',
    dataIndex: 'judgmentMethods',
    ellipsis: { showTitle: true },
    render: (v: number[]) => (
      <EllipsisSpan
        value={v && v.length ? v.map(item => JudgmentMethodDisplay[item as JudgmentMethod]).join() : '--'}
      />
    ),
  },
  {
    title: '测试指标',
    dataIndex: 'testIndicatorDesc',
    ellipsis: { showTitle: true },
    render: (v: string, record: TestCasePageItemProps) => (
      <EllipsisSpan
        value={
          record.judgmentMethods?.length === 1 && record.judgmentMethods?.includes(JudgmentMethod.ARTIFICIAL) ? '-' : v
        }
      />
    ),
  },
  {
    title: '状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (v: StatusType) => (
      <Tag border="solid" color={v === StatusType.ENABLE ? 'success' : 'default'}>
        {StatusDisplay[v]}
      </Tag>
    ),
  },
  {
    title: '当前版本号',
    dataIndex: 'versionNo',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后编辑人',
    dataIndex: 'createUser',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后编辑时间',
    dataIndex: 'createTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'} />,
  },
];

interface FilterProps {
  name?: string;
  abilityDomain?: number;
  status?: number;
}

const InverterTestCaseManagement = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const pagingInfo = useSearchParams<SearchParams>();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [update, updateFn] = useUpdate();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const hasAddEditPermission = useHasPermission(PermissionsType.B_NEWEDITINVERTERTESTCASESBUTTON); // 新建/编辑
  const hasEnablePermission = useHasPermission(PermissionsType.B_QTINVERTERTESTCASESBUTTON); // 启用停用
  const hasDeletePermission = useHasPermission(PermissionsType.B_DELETEINVERTERTESTCASESBUTTON); // 删除

  const { data, loading } = useRequest(
    () =>
      apiV2TestCasePagePost({
        ...searchParams,
        type: CaseType.HARDWARE,
        hardwareType: HardwareTypeType.INVERTER,
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        setTotalCount(res.total ?? 0);
        return res.list;
      }),
    {
      refreshDeps: [searchParams, pageOffset, pageSize, update],
    }
  );

  const onDelete = (ids: number[]) => {
    Modal.confirm({
      title: '确定删除所选用例吗？',
      onOk() {
        apiV2TestCaseDeletePost({ caseIds: ids }).then(updateFn);
      },
    });
  };

  // 启用/禁用
  const onEnable = (id?: number, status?: StatusType) => {
    if (isNil(id)) return;

    if (status === StatusType.ENABLE) {
      Modal.confirm({
        title: '确定停用该测试用例吗？',
        onOk() {
          apiV2TestCaseSwitchStartOrStopPost({
            id,
          }).then(updateFn);
        },
      });
    } else {
      apiV2TestCaseSwitchStartOrStopPost({
        id,
      }).then(updateFn);
    }
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 191,
      fixed: 'right' as 'right',
      render: (value: undefined, record: TestCasePageItemProps) => {
        return (
          <Space size={16}>
            {hasDeletePermission && (
              <Button
                type="link"
                disabled={record.status !== StatusType.DISABLE}
                onClick={() => onDelete([record.caseId!])}
              >
                删除
              </Button>
            )}

            <Button type="link" onClick={() => navigate(`/inverter/testCase/management/view/${record.caseId}`)}>
              查看
            </Button>
            {hasAddEditPermission && (
              <Button
                type="link"
                disabled={record.status !== StatusType.DISABLE}
                onClick={() => navigate(`/inverter/testCase/management/edit/${record.id}`)}
              >
                编辑
              </Button>
            )}
            {hasEnablePermission && (
              <Button type="link" onClick={() => onEnable(record.caseId, record.status)}>
                {record.status === StatusType.DISABLE
                  ? StatusDisplay[StatusType.ENABLE]
                  : StatusDisplay[StatusType.DISABLE]}
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const onFinish = (v: FilterProps) => {
    setSearchParams({
      ...v,
      type: CaseType.HARDWARE,
      hardwareType: HardwareTypeType.INVERTER,
      abilityDomain: !isNil(v.abilityDomain) ? [v.abilityDomain] : undefined,
    });
    setPageOffset(1);
  };

  const onReset = () => {
    const params = {
      name: undefined,
      abilityDomain: undefined,
      status: undefined,
    };
    setSearchParams({ type: CaseType.HARDWARE, hardwareType: HardwareTypeType.INVERTER, ...params });
    setPageOffset(1);
  };

  const filters = (
    <CustomFilter form={form} onFinish={v => onFinish(v as FilterProps)} onReset={onReset}>
      <Form.Item label="用例名称/编号" name="name">
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item label="所属能力域" name="abilityDomain">
        <Select
          options={Object.entries(AbilityDomainDisplay).map(([k, v]) => ({
            label: v,
            value: Number(k),
          }))}
          placeholder="请选择"
        />
      </Form.Item>

      <Form.Item label="状态" name="status">
        <Select
          options={Object.entries(StatusDisplay).map(([k, v]) => ({
            label: v,
            value: Number(k),
          }))}
          placeholder="请选择"
        />
      </Form.Item>
    </CustomFilter>
  );

  const rowSelection = {
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRows(selectedRows.map(k => k.caseId as number));
    },
    // selectedRowKeys: selectedRows,
    getCheckboxProps: (record: TestCasePageItemProps) => ({
      disabled: record.status !== StatusType.DISABLE,
    }),
  };

  return (
    <Wrapper filters={filters} breadCrumb={<BreadCrumb />}>
      <Space size={8} style={{ marginBottom: 10 }}>
        {hasAddEditPermission && (
          <Button type="primary" icon={<PlusOutlined />} onClick={() => navigate(`/inverter/testCase/management/add`)}>
            添加测试用例
          </Button>
        )}
        <Button type="primary" disabled={selectedRows.length === 0} onClick={() => onDelete(selectedRows)}>
          批量删除
        </Button>
      </Space>
      <Table
        columns={buildColumns}
        dataSource={data}
        rowKey="id"
        loading={loading}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
      />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default InverterTestCaseManagement;
