import { Form, Input, Modal } from '@maxtropy/components';
import React, { FC, useEffect, useState } from 'react';

export interface IEditLevelDescModal {
  modalOpen: boolean;
  preDesc?: string;
  setModalOpen: (val: boolean) => void;
  onSubmit?: (desc: string) => void;
}

const EditLevelDescModal: FC<IEditLevelDescModal> = props => {
  const { modalOpen, setModalOpen, onSubmit, preDesc } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      desc: preDesc,
    });
  }, []);

  const onOk = () => {
    form
      .validateFields()
      .then(() => {
        const values = form.getFieldsValue();
        onSubmit?.(values.desc);
        setModalOpen(false);
      })
      .catch(() => {});
  };

  return (
    <Modal
      open={modalOpen}
      contentClassName="modal-form-content"
      title="编辑等级说明"
      destroyOnClose
      onCancel={() => setModalOpen(false)}
      onOk={onOk}
    >
      <Form form={form} labelCol={{ span: 6 }} labelAlign="left">
        <Form.Item
          name="desc"
          label="等级说明"
          rules={[
            { required: true, message: '请输入等级说明' },
            { max: 100, message: '不超过100个字符' },
          ]}
        >
          <Input.TextArea style={{ width: 600 }} placeholder="请输入" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditLevelDescModal;
