import { Form, message, Modal } from '@maxtropy/components';
import React, { useState } from 'react';
import { apiV2TestTaskBatchLinkExperimentPost } from '@maxtropy/pv-test-apis-v2';
import InverterTable from './InverterTable';

interface BatchLinkExperimentProp {
  onCancel: () => void;
  updateFn: () => void;
  ids?: number[];
}

const BatchLinkExperiment = ({ onCancel, updateFn, ids }: BatchLinkExperimentProp) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>();
  const [form] = Form.useForm();

  const onOk = () => {
    if (selectedRowKeys && selectedRowKeys?.length > 0) {
      apiV2TestTaskBatchLinkExperimentPost({
        deviceId: selectedRowKeys[0],
        ids,
      }).then(() => {
        onCancel();
        updateFn();
      });
    } else {
      message.warning('请选择实验组');
    }
  };

  return (
    <Modal title="批量设置实验组" bodyScroll size="big" open={true} onCancel={onCancel} onOk={onOk}>
      <InverterTable form={form} setSelectedRowKeys={setSelectedRowKeys} />
    </Modal>
  );
};

export default BatchLinkExperiment;
