import { useState } from 'react';
import { Modal, message, Form, Table, EllipsisSpan, Button } from '@maxtropy/components';
import { apiV2TestTaskImportSelfEvaluationFormPost } from '@maxtropy/pv-test-apis-v2';
import { isNil } from 'lodash';
import Upload from '../Upload';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Space } from 'antd';

type Props = {
  taskId?: number | string;
  setOpen: (visible: boolean) => void;
  updateFn: () => void;
};

const columns = [
  {
    title: '材料名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '材料类型',
    dataIndex: 'level',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ?? '--'} />,
  },
  {
    title: '问题描述',
    dataIndex: 'description',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={<span style={{ color: 'var(--mx-warning-color)' }}>{v}</span>} />,
  },
];

const UploadAssess = ({ setOpen, updateFn, taskId }: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorList, setErrorList] = useState<any[]>([]);

  const onFinish = () => {
    if (isNil(taskId)) return;

    form.validateFields().then(values => {
      const { fileKey } = form.getFieldsValue();
      setLoading(true);
      apiV2TestTaskImportSelfEvaluationFormPost({ fileKey: [fileKey], id: Number(taskId) })
        .then(res => {
          if (res?.length) {
            setErrorList(res ?? []);
          } else {
            message.success('导入成功');
            updateFn?.();
            setOpen(false);
          }
        })
        .finally(() => setLoading(false));
    });
  };

  return (
    <Modal
      title="导入自评结果"
      open={true}
      onCancel={() => setOpen(false)}
      contentClassName="modal-form-content"
      confirmLoading={loading}
      footer={
        errorList.length > 0 ? (
          <Button type="primary" onClick={() => setOpen(false)}>
            知道了
          </Button>
        ) : (
          <Space size={8}>
            <Button onClick={() => setOpen(false)}>取消</Button>
            <Button type="primary" onClick={onFinish}>
              确定
            </Button>
          </Space>
        )
      }
    >
      {errorList.length > 0 ? (
        <>
          <div style={{ marginBottom: 10 }}>
            <CloseCircleOutlined style={{ color: 'var(--mx-error-color)' }} />
            <span style={{ color: 'var(--mx-text-secondary-color)', marginLeft: 4 }}>导入结束，请检查以下问题项目</span>
          </div>
          <Table rowKey="id" columns={columns} dataSource={errorList} loading={loading} />
        </>
      ) : (
        <Form form={form}>
          <Form.Item label="选择文件" rules={[{ required: true, message: '请先上传文件' }]} name="fileKey">
            <Upload
              action="/api/v2/upload/upload"
              name="file"
              listType="text"
              accept={['.xlsx']}
              uploadText="选择导入文件"
              fileSize={50}
              tip="仅限上传Excel"
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default UploadAssess;
