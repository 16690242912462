import { FC, ReactNode, Suspense, useEffect } from 'react';
import { SwitchStaff, HeadNavigation, SiderMenu, SystemContent, UserContent } from '@maxtropy/rc-components';
import { Outlet } from 'react-router-dom';
import { activateTheme, Themes } from '@maxtropy/components';
import { Divider, Space } from 'antd';
import { MenuItem } from '@maxtropy/rc-components/es/HeadNavigation';

const devMenu: MenuItem = {
  currentId: 'dev',
  name: '开发支持',
  level: 1,
  openType: 0,
  parentId: '',
  transitPagePath: null,
  rootId: 'dev',
  weighting: 1000,
  children: [
    {
      currentId: 'dev/1',
      name: '测试工具',
      level: 2,
      openType: 0,
      parentId: 'dev/1',
      transitPagePath: null,
      weighting: 1,
      rootId: 'dev',
      children: [
        {
          currentId: 'dev/1/1',
          rootId: 'dev',
          level: 3,
          name: '新增基础信息',
          openType: 0,
          parentId: 'dev/1',
          transitPagePath: '/testTools/inverterTestManagement/baseInfo/add',
          weighting: 1,
        },
      ],
    },
  ],
};

const TenantLayout: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: '#1b1b1b',
          padding: '0 20px',
        }}
      >
        <SystemContent />
        <HeadNavigation overlayStyle={{ flex: '1 1', minWidth: 0 }} devMenu={devMenu}></HeadNavigation>
        <Space split={<Divider type="vertical" />}>
          <SwitchStaff />
          <UserContent />
        </Space>
      </div>
      <div style={{ display: 'flex', height: 'calc(100vh - 50px)' }}>
        <SiderMenu devMenu={devMenu} overlayStyle={{ height: '100%' }}></SiderMenu>
        <div style={{ flex: 1, overflowY: 'auto' }}>
          <div className="router-box-outer">
            <div className="router-box-inner">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

// 其他页面路由出口
export const MainOutlet: FC = () => {
  useEffect(() => {
    activateTheme(Themes.DARK);
  }, []);

  return (
    <Suspense fallback={null}>
      <TenantLayout>
        <Outlet />
      </TenantLayout>
    </Suspense>
  );
};
