import { Button, Input, Wrapper, Form, FormTitle, SubContent, ShowInput, DatePicker } from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import { useEffect } from 'react';
import { apiV2TestTaskCreatePost, apiV2TestTaskDetailPost, apiV2TestTaskUpdatePost } from '@maxtropy/pv-test-apis-v2';
import { useNavigate, useParams } from 'react-router-dom';
import { isNil } from 'lodash';
import dayjs from 'dayjs';
import { BreadCrumb } from '@maxtropy/rc-components';

interface AddBaseInfoProps {
  isEdit?: boolean;
}

const AddBaseInfo: React.FC<AddBaseInfoProps> = ({ isEdit }) => {
  const [form] = Form.useForm();
  const { id: taskId } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const customRule = [
    { max: 100, message: '不超过100个字符' },
    {
      required: true,
      pattern: /^[\u4e00-\u9fa5a-zA-Z0-9\s\p{P}\p{S}]+$/u,
      message: '只能输入中英文、数字及符号',
    },
  ];

  useEffect(() => {
    if (isNil(taskId)) return;
    apiV2TestTaskDetailPost({ id: taskId }).then(res => {
      form.setFieldsValue({
        name: res?.name,
        sampleName: res?.sampleName,

        versionNo: res?.versionNo,
        sampleSource: res?.sampleSource,
        submissionDate: dayjs(String(res?.submissionDate)),
        applyPerson: res?.applyPerson,
        applyPersonAddress: res?.applyPersonAddress,
        manufacture: res?.manufacture,
        manufactureAddress: res?.manufactureAddress,
        testEnterprise: res?.testEnterprise,
        testPlace: res?.testPlace,
      });
    });
  }, [taskId]);

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      if (isEdit && !isNil(taskId)) {
        apiV2TestTaskUpdatePost({ ...values, type: 1, id: taskId }).then(res => {
          navigate(-1);
        });
      } else {
        apiV2TestTaskCreatePost({ ...values, type: 1 }).then(res => {
          navigate(-1);
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Wrapper breadCrumb={<BreadCrumb options={[{ name: `${isEdit ? '编辑' : '添加'}测试任务` }]} />}>
      <FormTitle title={`${isEdit ? '编辑' : '添加'}测试任务`} />
      <SubContent>
        <Form form={form} layout="vertical">
          <Row>
            <Col span={8}>
              <Form.Item
                name="name"
                label="任务名称"
                rules={[{ required: true, message: '请输入任务名称' }, ...customRule]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="任务类型" required>
                <ShowInput value={'软件测试'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="sampleName"
                label="样品名称"
                rules={[{ required: true, message: '请输入样品名称' }, ...customRule]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item
                name="versionNo"
                label="版本号"
                rules={[{ required: true, message: '请输入版本号' }, ...customRule]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="sampleSource"
                label="样品来源"
                rules={[{ required: true, message: '请输入样品来源' }, ...customRule]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="submissionDate" label="送检日期" rules={[{ required: true, message: '请输入送检日期' }]}>
                <DatePicker style={{ width: '100%' }} placeholder="请选择" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item
                name="applyPerson"
                label="申请人"
                rules={[{ required: true, message: '请输入申请人' }, ...customRule]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="applyPersonAddress"
                label="申请人地址"
                rules={[{ required: true, message: '请输入申请人地址' }, ...customRule]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="manufacture"
                label="制造商"
                rules={[{ required: true, message: '请输入制造商' }, ...customRule]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item
                name="manufactureAddress"
                label="制造商地址"
                rules={[{ required: true, message: '请输入制造商地址' }, ...customRule]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="testEnterprise"
                label="测试单位"
                rules={[{ required: true, message: '请输入测试单位' }, ...customRule]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="testPlace"
                label="测试地点"
                rules={[{ required: true, message: '请输入测试地点' }, ...customRule]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </SubContent>
      <Space size={8} style={{ marginLeft: 32 }}>
        <Button type="primary" onClick={onFinish}>
          保存
        </Button>
        <Button onClick={() => navigate(`/testTasks/management/list`)}>取消</Button>
      </Space>
    </Wrapper>
  );
};

export default AddBaseInfo;
