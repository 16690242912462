import {
  Button,
  EllipsisSpan,
  Table,
  Wrapper,
  Form,
  FormTitle,
  SubContent,
  ShowInput,
  BaseInfoContent,
  Paging,
  usePaging,
} from '@maxtropy/components';
import { useEffect, useState } from 'react';
import { LevelType, LevelTypeDisplay } from '@/common/util';
import { V2TestCaseVersionPagePostResponse, apiV2TestCaseVersionPagePost } from '@maxtropy/pv-test-apis-v2';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './index.module.scss';
import { Layout, Spin } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import { BreadCrumb } from '@maxtropy/rc-components';

const columns = [
  {
    title: '等级',
    dataIndex: 'level',
    ellipsis: { showTitle: true },
    render: (v: LevelType) => <EllipsisSpan value={LevelTypeDisplay[v as LevelType]} />,
  },
  {
    title: '分数',
    dataIndex: 'score',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '等级说明',
    dataIndex: 'desc',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export type VersionItem = Exclude<V2TestCaseVersionPagePostResponse['list'], undefined>[number];

const ViewTestCases: React.FC = () => {
  const [form] = Form.useForm();
  const { id: caseId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [openSider, setOpenSider] = useState(true); // 左侧树形toggle
  const [currentVersion, setCurrentVersion] = useState<VersionItem>(); // 当前版本号
  const pagingInfo = usePaging();
  const { pageOffset, setTotalCount, setPageOffset } = pagingInfo;

  const { data, loading } = useRequest(
    () =>
      apiV2TestCaseVersionPagePost({
        caseId: Number(caseId),
        page: pageOffset,
        size: 20,
      }).then(res => {
        setTotalCount(res.total ?? 0);
        setCurrentVersion(res.list?.[0]);
        return res.list ?? [];
      }),
    {
      refreshDeps: [caseId, pageOffset],
    }
  );

  return (
    <Wrapper breadCrumb={<BreadCrumb options={[{ name: '查看软件测试用例' }]}/>} className={styles.wrapper}>
      <FormTitle
        title="查看测试用例"
        extraContent={<Button onClick={() => navigate(`/testCases/management/list`)}>返回</Button>}
      ></FormTitle>

      <Spin spinning={loading}>
        {' '}
        <Layout className={styles.layout}>
          <Layout.Sider width={openSider ? 260 : 0} style={{ transition: 'all 0.2s', background: '#232324' }}>
            <div className={styles.leftContent}>
              <div className={styles.leftTitle}>版本记录</div>
              <div>
                {data?.map((item, index) => (
                  <div
                    className={`${styles.card} ${currentVersion?.id === item.id ? styles.active : ''}`}
                    onClick={() => setCurrentVersion(item)}
                  >
                    <div className={`${styles.circle} ${index > 0 ? styles.line : ''}`}></div>
                    <div>
                      <div className={styles.version}>{item.versionNo} </div>
                      <div className={styles.desc}>最后编辑人：{item.createUser}</div>
                      <div className={styles.desc}>
                        最后编辑时间：
                        {item.createTime ? dayjs(item.createTime as string).format('YYYY-MM-DD HH:mm:ss') : '--'}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <Paging className={styles.page} pagingInfo={pagingInfo} hideCustomTotal showSizeChanger={false} />
            </div>
          </Layout.Sider>
          <Layout.Content className={styles.content}>
            <div
              className={styles.toggleBtn}
              style={{ left: openSider ? -41 : 0 }}
              onClick={() => setOpenSider(!openSider)}
            >
              {openSider ? <LeftOutlined /> : <RightOutlined />}
            </div>
            <Form form={form}>
              <SubContent title="基础信息">
                <BaseInfoContent colNum={3} layout="horizontal">
                  <Form.Item label="用例名称">
                    <ShowInput value={currentVersion?.name} />
                  </Form.Item>
                  <Form.Item label="版本号">
                    <ShowInput value={currentVersion?.versionNo} />
                  </Form.Item>
                  <Form.Item label="用例类型">
                    <ShowInput value="软件测试" />
                  </Form.Item>
                  <Form.Item label="所属能力域">
                    <ShowInput value={currentVersion?.abilityDomainDesc} />
                  </Form.Item>
                  <Form.Item label="所属智能级">
                    <ShowInput value={currentVersion?.intelligentLevelDesc} />
                  </Form.Item>
                </BaseInfoContent>
              </SubContent>
              <SubContent title="标准要求">
                <Table
                  style={{ width: 600 }}
                  dataSource={currentVersion?.standardRequirement}
                  rowKey="key"
                  columns={columns}
                />
              </SubContent>
              <SubContent title="测试步骤">
                <Form.Item>
                  <ShowInput value={currentVersion?.testProcedure} />
                </Form.Item>
              </SubContent>
            </Form>
          </Layout.Content>
        </Layout>
      </Spin>
    </Wrapper>
  );
};

export default ViewTestCases;
