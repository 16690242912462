import {
  Form,
  message,
  Modal,
} from '@maxtropy/components';
import React, { useState } from 'react';
import {
  apiV2TestTaskBatchLinkControlGroupPost,
} from '@maxtropy/pv-test-apis-v2';
import InverterTable from './InverterTable';

interface BatchLinkControlGroupProp {
  onCancel: () => void;
  updateFn: () => void;
  ids?: number[];
}

const BatchLinkControlGroup = ({ onCancel, updateFn, ids }: BatchLinkControlGroupProp) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>();
  const [form] = Form.useForm();

  const onOk = () => {
    if (selectedRowKeys && selectedRowKeys?.length > 0) {
      apiV2TestTaskBatchLinkControlGroupPost(
        {
          deviceId: selectedRowKeys[0],
          ids,
        }
      ).then(() => {
        onCancel();
        updateFn();
      });
    } else {
      message.warning('请选择对照组');
    }
  };

  return (
    <Modal title="批量设置对照组" size="big" bodyScroll open={true} onCancel={onCancel} onOk={onOk}>
      <InverterTable form={form} setSelectedRowKeys={setSelectedRowKeys} />
    </Modal>
  );
};

export default BatchLinkControlGroup;
