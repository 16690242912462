import { DatePicker, Form, message, Modal } from '@maxtropy/components';
import React, { useState } from 'react';
import { apiV2TestTaskBatchSetRangeTimePost } from '@maxtropy/pv-test-apis-v2';
import InverterTable from './InverterTable';
import dayjs, { Dayjs } from 'dayjs';
import { DatePickerProps } from 'antd';

const getYearMonth = (date: Dayjs) => date.year() * 12 + date.month();

export const disabled31DaysDate: DatePickerProps['disabledDate'] = (current, { from, type }) => {
  if (from) {
    const minDate = from.add(-30, 'days');
    const maxDate = from.add(30, 'days');

    switch (type) {
      case 'month':
        return getYearMonth(current) < getYearMonth(minDate) || getYearMonth(current) > getYearMonth(maxDate);

      default:
        return Math.abs(current.diff(from, 'days')) >= 31;
    }
  }

  return false;
};

interface BatchSetRangeTimeProp {
  onCancel: () => void;
  updateFn: () => void;
  ids?: number[];
}

const BatchSetRangeTime = ({ onCancel, updateFn, ids }: BatchSetRangeTimeProp) => {
  const [form] = Form.useForm();

  const onOk = () => {
    form.validateFields().then(values => {
      apiV2TestTaskBatchSetRangeTimePost({
        startTime: dayjs(values.analysisPeriod[0]).valueOf(),
        endTime: dayjs(values.analysisPeriod[1]).valueOf(),
        ids,
      }).then(() => {
        onCancel();
        updateFn();
      });
    });
  };

  return (
    <Modal title="批量设置分析时段" bodyScroll open={true} onCancel={onCancel} onOk={onOk}>
      <Form form={form} style={{ padding: '0 48px' }}>
        <Form.Item label="分析时段" rules={[{ required: true, message: '请选择时间段' }]} name="analysisPeriod">
          <DatePicker.RangePicker showTime disabledDate={disabled31DaysDate} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BatchSetRangeTime;
