import { Button, DatePicker, Form, InputNumber, message, Modal, Steps } from '@maxtropy/components';
import React, { useState } from 'react';
import { apiV2TestTaskSaveCasePost } from '@maxtropy/pv-test-apis-v2';
import InverterTable from './InverterTable';
import { ReferentType } from '@/common/util';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { Space } from 'antd';
import { disabled31DaysDate } from './BatchSetRangeTime';

interface BatchLinkExperimentProp {
  onCancel: () => void;
  updateFn: () => void;
  id?: number;
  referent?: ReferentType;
}

const UseCaseCalculation = ({ onCancel, updateFn, id, referent }: BatchLinkExperimentProp) => {
  const [experimentSelectedRowKeys, setExperimentSelectedRowKeys] = useState<number[]>();
  const [controlGroupSelectedRowKeys, setControlGroupSelectedRowKeys] = useState<number[]>();
  const [current, setCurrent] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>();

  const [form] = Form.useForm();
  const [tableForm] = Form.useForm();

  const theoreticalValue = Form.useWatch('theoreticalValue', form);

  const onOk = () => {
    form.validateFields().then(() => {
      const values = form.getFieldsValue(true);

      setLoading(true);
      apiV2TestTaskSaveCasePost({
        id,
        experimentalGroupDeviceId: experimentSelectedRowKeys![0],
        theoreticalValue: values.theoreticalValue,
        controlGroupDeviceId: isEmpty(controlGroupSelectedRowKeys) ? undefined : controlGroupSelectedRowKeys![0],
        startTime: dayjs(values.analysisPeriod[0]).valueOf(),
        endTime: dayjs(values.analysisPeriod[1]).valueOf(),
      })
        .then(() => {
          onCancel();
          updateFn();
        })
        .finally(() => setLoading(false));
    });
  };

  const onNextStep = () => {
    if (current === 0 && isEmpty(experimentSelectedRowKeys)) {
      message.warning('请选择实验组');
      return;
    }
    if (current === 1 && referent === ReferentType.CONTROL_GROUP && isEmpty(controlGroupSelectedRowKeys)) {
      message.warning('请选择对照组');
      return;
    }

    if (current === 1 && referent === ReferentType.THEORETICAL_VALUE && !theoreticalValue) {
      form.validateFields();
      return;
    }

    setCurrent(per => per + 1);
  };

  return (
    <Modal
      title="用例计算设置"
      size="big"
      open={true}
      bodyScroll
      footer={
        <Space size={8}>
          <Button onClick={onCancel}>取消</Button>
          {current === 2 ? (
            <Button type="primary" onClick={onOk} loading={loading}>
              确定
            </Button>
          ) : (
            <Button type="primary" onClick={onNextStep}>
              下一步
            </Button>
          )}
        </Space>
      }
      onCancel={onCancel}
    >
      <Steps
        current={current}
        size="small"
        style={{ padding: '0 216px 32px' }}
        items={[
          {
            title: '选择实验组',
          },
          {
            title: referent === ReferentType.CONTROL_GROUP ? '选择对照组' : '设置理论值',
          },
          {
            title: '设置分析时段',
          },
        ]}
      />
      {current === 0 && (
        <InverterTable bottomTitle="实验组" form={tableForm} setSelectedRowKeys={setExperimentSelectedRowKeys} />
      )}
      {current === 1 && referent === ReferentType.CONTROL_GROUP && (
        <InverterTable bottomTitle="对照组" form={tableForm} setSelectedRowKeys={setControlGroupSelectedRowKeys} />
      )}
      <Form style={{ padding: '0 240px' }} form={form}>
        {current === 1 && referent === ReferentType.THEORETICAL_VALUE && (
          <Form.Item label="理论值" name="theoreticalValue" rules={[{ required: true, message: '请输入' }]}>
            <InputNumber
              style={{ width: '100%' }}
              placeholder="请输入"
              step={0.000000001}
              precision={9}
              min={-999999999999}
              max={999999999999}
            />
          </Form.Item>
        )}
        {current === 2 && (
          <Form.Item label="分析时段" rules={[{ required: true, message: '请选择时间段' }]} name="analysisPeriod">
            <DatePicker.RangePicker showTime disabledDate={disabled31DaysDate} />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default UseCaseCalculation;
