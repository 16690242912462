import { useNavigate, useParams } from 'react-router-dom';
import { Divider, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useRequest } from 'ahooks';
import {
  Button,
  EllipsisSpan,
  Table,
  Wrapper,
  Form,
  FormTitle,
  SubContent,
  usePaging,
  Paging,
} from '@maxtropy/components';
import {
  apiV2TestTaskAssessmentPost,
  apiV2TestTaskCaseLatestInfoPost,
  apiV2TestTaskCasePagePost,
  apiV2TestTaskDetailPost,
} from '@maxtropy/pv-test-apis-v2';
import { buildColumns, DataType } from './TestCase';
import { HardwareType, JudgmentMethod, JudgmentMethodDisplay, LevelType, LevelTypeDisplay } from '@/common/util';
import IntelligentEvaluationCriteria from './TestCase/components/IntelligentEvaluationCritria';
import styles from '../ComponentTestManagement/TestCase/index.module.scss';
import { BreadCrumb } from '@maxtropy/rc-components';
import { isEmpty, isNil } from 'lodash';

const ViewTaskCase: React.FC = () => {
  const navigate = useNavigate();
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;
  const { id: taskId } = useParams<{ id: string }>();

  const { data: detailData } = useRequest(
    () =>
      apiV2TestTaskDetailPost({
        id: taskId,
      }),
    {
      refreshDeps: [setTotalCount],
    }
  );

  const { data, loading } = useRequest(
    () =>
      apiV2TestTaskCasePagePost({
        taskId,
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        setTotalCount(res.total ?? 0);
        return res.list || [];
      }),
    {
      refreshDeps: [setTotalCount, pageSize, pageOffset],
    }
  );

  const { data: assessmentData } = useRequest(
    () =>
      apiV2TestTaskAssessmentPost({
        id: taskId,
      }),
    {
      refreshDeps: [taskId],
    }
  );

  const { data: caseLatestInfo } = useRequest(
    () =>
      apiV2TestTaskCaseLatestInfoPost({
        id: taskId,
      }),
    {
      refreshDeps: [taskId],
    }
  );

  const columns: ColumnsType<DataType> = [
    ...buildColumns,
    {
      title: '判定方式',
      dataIndex: 'judgmentMethod',
      fixed: 'right' as const,
      width: 150,
      render: (v: JudgmentMethod, record) => {
        if (isEmpty(record.judgmentMethods)) {
          return '--';
        } else if (record.judgmentMethods?.length === 1) {
          return <EllipsisSpan value={JudgmentMethodDisplay[record.judgmentMethods[0] as JudgmentMethod]} />;
        }
        return <EllipsisSpan value={JudgmentMethodDisplay[v]} />;
      },
    },
    {
      title: '结果',
      dataIndex: 'testResult',
      fixed: 'right' as const,
      width: 150,
      render: (v, record) => {
        const levelObj = record.standardRequirement?.find(item => item.level === v);
        if (!levelObj) {
          return '--';
        }

        return <EllipsisSpan value={`${LevelTypeDisplay[levelObj.level as LevelType]}（${levelObj.score ?? 0}分）`} />;
      },
    },
    {
      title: '操作',
      dataIndex: 'operation',
      width: 100,
      fixed: 'right' as const,
      render: (v, record) => (
        <>
          {record.judgmentMethods &&
            (record.judgmentMethods.length == 1 || record.judgmentMethod === JudgmentMethod.ARTIFICIAL) && (
              <Button
                type="link"
                disabled={isNil(record.fileKeys)}
                onClick={() => navigate(`/testTasks/management/viewFile/${record.id}`)}
              >
                查看资料
              </Button>
            )}
          {record.judgmentMethod === JudgmentMethod.SYSTEM && (
            <Button type="link" disabled={!record.testResult} href={`/api/v2/test-task/downloadData?id=${record.id}`}>
              下载数据
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <Wrapper breadCrumb={<BreadCrumb options={[{ name: '查看任务测试用例' }]} />}>
      <FormTitle title={'查看任务测试用例'}></FormTitle>
      <SubContent>
        <Form isOrigin={true} layout="horizontal" className={styles.taskInfo}>
          <Space
            size={8}
            align="center"
            split={<Divider type="vertical" style={{ borderColor: 'rgba(255,255,255,0.2)' }} />}
          >
            <Form.Item label="任务名称">{detailData?.name ?? '--'}</Form.Item>
            <Form.Item label="任务类型">硬件测试</Form.Item>
            <Form.Item label="硬件类型">逆变器</Form.Item>
            <Form.Item label="样品名称">{detailData?.sampleName ?? '--'}</Form.Item>
          </Space>
        </Form>
        <div>
          <span style={{ color: 'var(--mx-error-color)', marginRight: 4 }}>{caseLatestInfo?.stoppedCount ?? 0}</span>
          条用例已停用，
          <span style={{ color: 'var(--mx-warning-color)', marginRight: 4 }}>{caseLatestInfo?.updatingCount ?? 0}</span>
          条用例版本有更新（对应用例版本号已标星<span style={{ color: 'var(--mx-warning-color)' }}>*</span> ）
        </div>
        <Table rowKey="id" columns={columns} dataSource={data} loading={loading} scroll={{ x: 600 }} />
        <Paging pagingInfo={pagingInfo} />
        {/* 测试结果 */}
        <IntelligentEvaluationCriteria data={assessmentData} type={HardwareType.Inverter} />
        <Space size={8} className={'sticky-footer-left'}>
          <Button wrapStyle={{ marginTop: 32 }} onClick={() => navigate(-1)}>
            返回
          </Button>
        </Space>
      </SubContent>
    </Wrapper>
  );
};

export default ViewTaskCase;
