import { CreateRoutersProps, DOMRouterOpts, RoutePathItem, addPermissionByLoop } from '@maxtropy/components';
import { createBrowserRouter, Outlet } from 'react-router-dom';
import AuthorizedPermission from '@/components/AuthorizedPermission';
import mainRoutes from './main';
import homeRoutes from './home';
import { MainOutlet } from './outlets';

const routes: RoutePathItem[] = [
    {
      element: <Outlet />,
      children: [
        {
          element: <MainOutlet />,
          children: [...homeRoutes, ...mainRoutes],
        },
      ],
    },
  ];
  
  export const createRouters = (parameters: CreateRoutersProps, opt?: DOMRouterOpts) => {
    const { routes, render } = parameters;
    const pagesRoutes = addPermissionByLoop({ routes, render });
    return createBrowserRouter(pagesRoutes, opt);
  };
  
  // 递归添加AuthorizedPermission,并创建路由对象
  const routers = createRouters(
    {
      routes,
      render: (pers, ele) => {
        return <AuthorizedPermission permissionKey={pers}>{ele}</AuthorizedPermission>;
      },
    }
  );
  
  export default routers;