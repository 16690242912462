import {
  Button,
  EllipsisSpan,
  Input,
  Table,
  Wrapper,
  useSearchParams,
  useUpdate,
  Tag,
  Paging,
  CustomFilter,
  Form,
  Select,
  Dropdown,
  Modal,
  getRealUrl,
} from '@maxtropy/components';
import { Space } from 'antd';
import { useRequest } from 'ahooks';
import { useState } from 'react';
import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';
import { CaseType, TaskStatusDisplay, TaskStatusType, taskStatusOptions, useHasPermission } from '@/common/util';
import {
  V2TestTaskPagePostRequest,
  apiV2TestTaskCheckExecutionPost,
  apiV2TestTaskDeletePost,
  apiV2TestTaskGenerateWordPost,
  apiV2TestTaskPagePost,
} from '@maxtropy/pv-test-apis-v2';
import { useNavigate } from 'react-router-dom';
import { isNil } from 'lodash';
import { PermissionsType } from '@/common/permissionsConst';
import { BreadCrumb } from '@maxtropy/rc-components';

const columns = [
  {
    title: '任务编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '任务名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '任务类型',
    dataIndex: 'type',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={'软件测试'} />,
  },
  {
    title: '样品名称',
    dataIndex: 'sampleName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '制造商',
    dataIndex: 'manufacture',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '送检日期',
    dataIndex: 'submissionDate',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD') : '--'} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (v: TaskStatusType) => (
      <Tag border="solid" color={v === TaskStatusType.COMPLETED ? 'success' : '#4A90E2'}>
        {TaskStatusDisplay[v]}
      </Tag>
    ),
  },
  {
    title: '最后编辑人',
    dataIndex: 'updateUser',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后编辑时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm')} />,
  },
];

interface SearchParams extends Omit<V2TestTaskPagePostRequest, 'page' | 'size'> {}

const TestTasks: React.FC = () => {
  const [form] = Form.useForm();
  const pagingInfo = useSearchParams<SearchParams>(20, {});
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;
  const [update, updateFn] = useUpdate();
  const navigate = useNavigate();

  const hasAddPermission = useHasPermission(PermissionsType.B_NEWEDITSOFTWARETESTTASKBUTTON); // 新建/编辑
  const hasDelPermission = useHasPermission(PermissionsType.B_DELETESOFTWARETESTINGTASKBUTTON); // 删除

  const vieMenu = [
    {
      key: 1,
      label: '查看基础信息',
    },
    {
      key: 2,
      label: '查看测试用例',
    },
  ];

  const editMenu = [
    {
      key: 1,
      label: '编辑基础信息',
    },
    {
      key: 2,
      label: '编辑测试用例',
    },
  ];

  const { data, loading } = useRequest(
    () =>
      apiV2TestTaskPagePost({
        ...searchParams,
        type: CaseType.SOFTWARE,
        page: searchParams.pageOffset,
        size: searchParams.pageSize,
      }).then(res => {
        setTotalCount(res.total ?? 0);
        return res.list;
      }),
    {
      refreshDeps: [searchParams, setTotalCount, update],
    }
  );

  const rowSelection = {
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedIds(selectedRows.map(k => k.id as number));
    },
  };

  const onDelete = (idList: number[]) => {
    const ids = idList.filter(m => !isNil(m));
    if (ids.length === 0) return;
    Modal.confirm({
      title: '确定删除所选任务？',
      onOk() {
        apiV2TestTaskDeletePost({ ids }).then(updateFn);
      },
    });
  };

  // 生成报告
  const createReport = (id: number) => {
    apiV2TestTaskCheckExecutionPost({ id }).then(res => {
      if (res) {
        apiV2TestTaskGenerateWordPost({ id }).then(_ => {
          updateFn();
        });
      } else {
        Modal.confirm({
          title: `用例未全部执行，是否继续生成报告？`,
          onOk: () =>
            apiV2TestTaskGenerateWordPost({ id }).then(_ => {
              updateFn();
            }),
        });
      }
    });
  };

  // 下载报告
  const downLoadReport = (fileKey: string) => {
    window.open(getRealUrl(fileKey), '__blank');
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 240,
      fixed: 'right' as 'right',
      render: (value: undefined, record: any) => {
        return (
          <Space size={16}>
            {hasDelPermission && (
              <Button type="link" onClick={() => onDelete([record.id])}>
                删除
              </Button>
            )}

            <Dropdown
              type="link"
              menu={{
                items: vieMenu,
                onClick: ({ key }) => {
                  if (Number(key) === 1) {
                    navigate(`/testTasks/management/list/viewBase/${record.id}`);
                  } else {
                    navigate(`/testTasks/management/list/viewCase/${record.id}`);
                  }
                },
              }}
            >
              查看
            </Dropdown>
            {record.status === TaskStatusType.TESTING && hasAddPermission && (
              <Dropdown
                type="link"
                menu={{
                  items: editMenu,
                  onClick: ({ key }) => {
                    if (Number(key) === 1) {
                      navigate(`/testTasks/management/list/editBase/${record.id}`);
                    } else {
                      navigate(`/testTasks/management/list/editCase/${record.id}`);
                    }
                  },
                }}
              >
                编辑
              </Dropdown>
            )}

            {record.fileKey ? (
              <Button type="link" onClick={() => downLoadReport(record.fileKey)}>
                下载报告
              </Button>
            ) : (
              <Button type="link" onClick={() => createReport(record.id!)}>
                生成报告
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const onFinish = (v: SearchParams) => {
    finish(v);
  };

  const onReset = () => {
    reset({});
  };

  const filters = (
    <CustomFilter form={form} onFinish={onFinish} onReset={onReset} initialValues={{}} collapseOpen={false}>
      <Form.Item label="任务名称/编号" name="name">
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item label="样品名称" name="sampleName">
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item label="制造商" name="manufacture">
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item label="状态" name="status">
        <Select options={taskStatusOptions} placeholder="请选择" />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper filters={filters} breadCrumb={<BreadCrumb />}>
      <Space size={8} style={{ marginBottom: 10 }}>
        {hasAddPermission && (
          <Button type="primary" icon={<PlusOutlined />} onClick={() => navigate(`/testTasks/management/list/addBase`)}>
            添加测试任务
          </Button>
        )}
        {hasDelPermission && (
          <Button type="primary" disabled={!selectedIds.length} onClick={() => onDelete(selectedIds)}>
            批量删除
          </Button>
        )}
      </Space>
      <Table
        columns={buildColumns}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        dataSource={data}
        rowKey="id"
        loading={loading}
      />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default TestTasks;
