import {
  PopConfirm,
  Button,
  EllipsisSpan,
  Input,
  Table,
  Wrapper,
  Form,
  FormTitle,
  SubContent,
  ShowInput,
  Radio,
} from '@maxtropy/components';
import { Cascader, Col, Row, Space, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { IntelligentLevel, IntelligentLevelDisplay } from '@/common/util';
import {
  apiV2TestCaseCreatePost,
  apiV2TestCaseDetailPost,
  apiV2TestCaseSoftwareAbilityDomainListPost,
  apiV2TestCaseUpdatePost,
} from '@maxtropy/pv-test-apis-v2';
import { useNavigate, useParams } from 'react-router-dom';
import { isNil } from 'lodash';
import { useRequest } from 'ahooks';
import EditLevelDescModal from './EditLevelDescModal';
import { BreadCrumb } from '@maxtropy/rc-components';

interface EditTestCaseProps {
  isEdit?: boolean;
}

interface DataType {
  key: number;
  level: number;
  levelDisplay: string;
  score: number;
  desc: string;
}

const columns = [
  {
    title: '等级',
    dataIndex: 'levelDisplay',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '分数',
    dataIndex: 'score',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '等级说明',
    dataIndex: 'desc',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v || '--'} />,
  },
];

const defaultTableData: DataType[] = [
  {
    key: 1,
    level: 4,
    levelDisplay: 'D类',
    score: 0,
    desc: '',
  },
  {
    key: 2,
    level: 3,
    levelDisplay: 'C类',
    score: 1,
    desc: '',
  },
  {
    key: 3,
    level: 2,
    levelDisplay: 'B类',
    score: 2,
    desc: '',
  },
  {
    key: 4,
    level: 1,
    levelDisplay: 'A类',
    score: 3,
    desc: '',
  },
];

const AddTestCase: React.FC<EditTestCaseProps> = ({ isEdit }) => {
  const [form] = Form.useForm();
  const { id: caseId } = useParams<{ id: string }>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [tableData, setTableData] = useState<DataType[]>(defaultTableData);
  const navigate = useNavigate();
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [currentRow, setCurrentRow] = useState<DataType>();
  const [loading, setLoading] = useState<boolean>(false);
  const [tempAbilityDomain, setTempAbilityDomain] = useState<number>();

  useEffect(() => {
    if (isNil(caseId)) return;
    setLoading(true);
    apiV2TestCaseDetailPost({ id: Number(caseId) })
      .then(res => {
        form.setFieldsValue({
          name: res.name,
          intelligentLevel: res.intelligentLevel,
          testProcedure: res.testProcedure,
        });
        const selectedList: number[] = [];
        const list = tableData.map(m => {
          const prevRow = res.standardRequirement?.find(k => k.level === m.level);

          if (prevRow) {
            selectedList.push(m.key);
          }

          return {
            ...m,
            level: prevRow?.level ?? m.level,
            score: prevRow?.score ?? m.score,
            desc: prevRow?.desc ?? m.desc,
          };
        });
        setSelectedKeys(selectedList);
        setTempAbilityDomain(res.abilityDomain);
        setTableData([...list]);
      })
      .finally(() => setLoading(false));
  }, [caseId]);

  const { data: softwareAbilityDomainData } = useRequest(
    () => apiV2TestCaseSoftwareAbilityDomainListPost().then(res => res.list ?? []),
    {
      refreshDeps: [],
    }
  );

  useEffect(() => {
    if (isNil(tempAbilityDomain) || isNil(softwareAbilityDomainData)) return;

    for (const item of softwareAbilityDomainData) {
      for (const ability of item.abilityList ?? []) {
        if (ability.id === tempAbilityDomain) {
          form.setFieldsValue({ abilityDomain: [item.id, ability.id] });
        }
      }
    }
  }, [softwareAbilityDomainData, tempAbilityDomain]);

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 191,
      fixed: 'right' as 'right',
      render: (value: undefined, record: any) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              // disabled={!selectedKeys.includes(record.key)}
              onClick={() => {
                setCurrentRow(record);
                setModalOpen(true);
              }}
            >
              编辑等级说明
            </Button>
          </Space>
        );
      },
    },
  ];

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      const param = {
        name: values.name,
        type: 1,
        abilityDomain: values.abilityDomain?.[1],
        intelligentLevel: values.intelligentLevel,
        testProcedure: values.testProcedure,
        standardRequirement: tableData
          .filter(k => selectedKeys.includes(k.key))
          .map(m => ({
            level: m.level,
            score: m.score,
            desc: m.desc,
          })),
      };
      setLoading(true);
      if (isNil(caseId)) {
        apiV2TestCaseCreatePost(param)
          .then(res => {
            navigate(`/testCases/management/list`);
          })
          .finally(() => setLoading(false));
      } else {
        apiV2TestCaseUpdatePost({ id: Number(caseId), ...param })
          .then(res => {
            navigate(`/testCases/management/list`);
          })
          .finally(() => setLoading(false));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedKeys(selectedRowKeys);
    },
    selectedRowKeys: selectedKeys,
  };

  return (
    <Wrapper breadCrumb={<BreadCrumb options={[{ name: `${isEdit ? '编辑' : '添加'}测试用例` }]} />}>
      <FormTitle title={`${isEdit ? '编辑' : '添加'}测试用例`} />
      <Spin spinning={loading}>
        <Form form={form} initialValues={{ type: 1 }}>
          <SubContent title="基础信息">
            <Row>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label="用例名称"
                  rules={[
                    { required: true, message: '请输入用例名称' },
                    { max: 100, message: '不超过100个字符' },
                    {
                      required: true,
                      pattern: /^[\u4e00-\u9fa5a-zA-Z0-9\s\p{P}\p{S}]+$/u,
                      message: '只能输入中英文、数字及符号',
                    },
                  ]}
                >
                  <Input placeholder="请输入" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Form.Item label="用例类型">
                  <ShowInput value={'软件测试'} />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Form.Item name="abilityDomain" label="所属能力域" rules={[{ required: true, message: '请选择' }]}>
                  <Cascader
                    disabled={isEdit}
                    options={softwareAbilityDomainData}
                    fieldNames={{ label: 'name', value: 'id', children: 'abilityList' }}
                    placeholder="请选择"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item name="intelligentLevel" label="所属智能级" rules={[{ required: true, message: '请选择' }]}>
                  <Radio.Group>
                    <Radio value={IntelligentLevel.BASIC}>{IntelligentLevelDisplay[IntelligentLevel.BASIC]}</Radio>
                    <Radio value={IntelligentLevel.OPERATE}>{IntelligentLevelDisplay[IntelligentLevel.OPERATE]}</Radio>
                    <Radio value={IntelligentLevel.ASSISTEd}>
                      {IntelligentLevelDisplay[IntelligentLevel.ASSISTEd]}
                    </Radio>
                    <Radio value={IntelligentLevel.HIGHLY}>{IntelligentLevelDisplay[IntelligentLevel.HIGHLY]}</Radio>
                    <Radio value={IntelligentLevel.COMPLETED}>
                      {IntelligentLevelDisplay[IntelligentLevel.COMPLETED]}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </SubContent>
          <SubContent title="标准要求">
            <Table
              style={{ width: 600 }}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              dataSource={tableData}
              rowKey="key"
              columns={buildColumns}
            />
          </SubContent>
          <SubContent title="测试步骤">
            <Form.Item name="testProcedure" rules={[{ max: 500, message: '不超过500个字符' }]}>
              <Input.TextArea style={{ width: 600 }} placeholder="请输入" />
            </Form.Item>
          </SubContent>
        </Form>
        <Space size={8} style={{ marginLeft: 32 }} className={'sticky-footer-left'}>
          <PopConfirm placement="top" title="确定根据当前信息创建新的用例版本吗？" onConfirm={onFinish}>
            <Button type="primary">保存</Button>
          </PopConfirm>
          <Button onClick={() => navigate(`/testCases/management/list`)}>取消</Button>
        </Space>
      </Spin>

      {modalOpen && (
        <EditLevelDescModal
          modalOpen={modalOpen}
          preDesc={currentRow?.desc}
          onSubmit={desc => {
            setTableData(tableData.map(m => (m.key === currentRow?.key ? { ...currentRow, desc } : m)));
          }}
          setModalOpen={val => setModalOpen(val)}
        />
      )}
    </Wrapper>
  );
};

export default AddTestCase;
