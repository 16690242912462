export const FILE_TYPE = {
  IMAGE: ['.jpeg', '.jpg', '.gif', '.png', 'bmp'],
  WORD: ['.doc', '.rtf', '.docx'],
  EXCEL: ['.xls', '.xlsx'],
  PDF: ['.pdf'],
  FILE: ['.txt', '.tiff', '.tif', '.rar', '.zip', '.7z', '.cab', '.iso', '.svg'],
};

// 获取请求前缀，主要适配微前端，如/ms-ds
export function getBase(): string {
  return '';
}

export const getRealUrl = (value: string) => {
  return /^http(s)?:\/\//.test(value) ? value : `${getBase()}/api/file-center/get?key=${encodeURIComponent(value)}`;
};

export function getFileSuffix(url: string): string {
  const fileArr = url.split('.');

  const suffix = fileArr[fileArr.length - 1].toLocaleLowerCase();

  return suffix ? `.${suffix}` : '';
}
