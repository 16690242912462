import { CustomFilter, EllipsisSpan, Form, Input, Paging, Select, Table, usePaging } from '@maxtropy/components';
import React, { useEffect, useMemo, useState } from 'react';
import {
  apiV2PlatformPvAreaListPost,
  apiV2PlatformPvInverterListPost,
  apiV2PlatformPvListPost,
  apiV2PlatformPvModulePagePost,
  apiV2PlatformPvStringListPost,
  apiV2PlatformPvStringPagePost,
  V2PlatformInverterPagePostRequest,
  V2PlatformInverterPagePostResponse,
  V2PlatformPvStringPagePostResponse,
} from '@maxtropy/pv-test-apis-v2';
import { useRequest } from 'ahooks';
import { ColumnsType } from 'antd/es/table';
import { isEmpty } from 'lodash';
import { FormInstance } from 'antd';

interface SearchParams extends Omit<V2PlatformInverterPagePostRequest, 'page' | 'size'> {}
export type DataType = Exclude<V2PlatformInverterPagePostResponse['list'], undefined>[number];

interface InverterTableProp {
  bottomTitle?: string;
  setSelectedRowKeys: (v: number[]) => void;
  form: FormInstance<any>;
}

enum ObjectType {
  COMPONENT = 1,
  STRING,
}

const InverterTable = ({ setSelectedRowKeys, bottomTitle, form }: InverterTableProp) => {
  const type = Form.useWatch('type', form);
  const areaId = Form.useWatch('areaId', form);
  const inverterId = Form.useWatch('inverterId', form);
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [searchParams, setSearchParams] = useState<SearchParams>({});
  const pvStationId = Form.useWatch('pvStationId', form);
  const [selectedRows, setSelectedRows] = useState<DataType[]>();
  const [data, setData] = useState<V2PlatformPvStringPagePostResponse['list']>();
  const [loading, setLoading] = useState<boolean>();

  const { data: pvList } = useRequest(() => apiV2PlatformPvListPost({}).then(res => res?.list ?? []), {
    refreshDeps: [],
  });

  const { data: areaList } = useRequest(
    () => apiV2PlatformPvAreaListPost({ id: pvStationId }).then(res => res?.list ?? []),
    {
      refreshDeps: [pvStationId],
    }
  );

  const { data: inverterList } = useRequest(
    () => apiV2PlatformPvInverterListPost({ id: areaId }).then(res => res?.list ?? []),
    {
      refreshDeps: [areaId],
    }
  );

  const { data: pvStringList } = useRequest(
    () => apiV2PlatformPvStringListPost({ id: inverterId }).then(res => res?.list ?? []),
    {
      refreshDeps: [inverterId],
    }
  );

  useEffect(() => {
    const Api =
      form.getFieldValue('type') === ObjectType.STRING ? apiV2PlatformPvStringPagePost : apiV2PlatformPvModulePagePost;
    setLoading(true);
    Api({
      ...searchParams,
      page: pageOffset,
      size: pageSize,
    })
      .then(res => {
        setData(res?.list ?? []);
        setTotalCount(res?.total ?? 0);
      })
      .finally(() => setLoading(false));
  }, [searchParams, pageOffset, pageSize, setTotalCount]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedRowKeys((selectedRows || []).map(item => item.deviceId) as number[]);
      setSelectedRows(selectedRows);
    },
  };

  const onFinish = (v: SearchParams) => {
    setSearchParams(v);
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({});
    setPageOffset(1);
  };

  const columns: ColumnsType<DataType> = useMemo(() => {
    return [
      {
        title: type === ObjectType.STRING ? '组串编号' : '组件编号',

        dataIndex: 'code',
        ellipsis: { showTitle: true },
        render: v => <EllipsisSpan value={v} />,
      },
      {
        title: '设备名称',
        dataIndex: 'deviceName',
        ellipsis: { showTitle: true },
        render: v => <EllipsisSpan value={v} />,
      },
      {
        title: '设备类型',
        dataIndex: 'deviceType',
        ellipsis: { showTitle: true },
        render: v => <EllipsisSpan value={v} />,
      },
      {
        title: '所属组串',
        dataIndex: 'pvStringName',
        ellipsis: { showTitle: true },
        render: v => <EllipsisSpan value={type === ObjectType.STRING ? '光伏组串' : v} />,
      },
      {
        title: '所属逆变器',
        dataIndex: 'inverterName',
        ellipsis: { showTitle: true },
        render: v => <EllipsisSpan value={v} />,
      },
    ];
  }, [data]);

  const filters = (
    <CustomFilter<SearchParams> form={form} onFinish={onFinish} onReset={onReset} colSpan={8}>
      <Form.Item name="type" label="对象类型" initialValue={ObjectType.COMPONENT}>
        <Select placeholder="请选择">
          <Select.Option value={ObjectType.COMPONENT}>光伏组件</Select.Option>
          <Select.Option value={ObjectType.STRING}>光伏组串</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="code" label="编号">
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item name="pvStationId" label="光伏站">
        <Select
          placeholder="请选择"
          onChange={() => {
            form.setFieldsValue({
              areaId: undefined,
              inverterId: undefined,
              pvStringId: undefined,
            });
          }}
          options={pvList?.map(item => ({ label: item.name, value: item.id }))}
        />
      </Form.Item>
      <Form.Item name="areaId" label="片区">
        <Select
          placeholder="请选择"
          onChange={() => {
            form.setFieldsValue({
              inverterId: undefined,
              pvStringId: undefined,
            });
          }}
          options={areaList?.map(item => ({ label: item.name, value: item.id }))}
        />
      </Form.Item>
      <Form.Item name="inverterId" label="所属逆变器">
        <Select placeholder="请选择" options={inverterList?.map(item => ({ label: item.name, value: item.id }))} />
      </Form.Item>
      {type === ObjectType.COMPONENT && (
        <Form.Item name="pvStringId" label="所属组串">
          <Select placeholder="请选择" options={pvStringList?.map(item => ({ label: item.name, value: item.id }))} />
        </Form.Item>
      )}
    </CustomFilter>
  );

  return (
    <>
      {filters}
      <Form>
        <div>
          <Table
            rowSelection={{
              type: 'radio',
              ...rowSelection,
            }}
            rowKey={record => record.id + '' + record.deviceId}
            columns={columns}
            dataSource={data}
            loading={loading}
            bottomLeftExtra={
              bottomTitle && !isEmpty(selectedRows) ? (
                <>
                  已选择{bottomTitle}： {selectedRows![0]?.code} {selectedRows![0]?.deviceName}
                </>
              ) : (
                <div />
              )
            }
          >
            <Paging pagingInfo={pagingInfo} />
          </Table>
        </div>
      </Form>
    </>
  );
};

export default InverterTable;
