export enum PermissionsType {
  // 软件测试
  // 软件测试用例3级
  P_SOFTWARETESTCASES = 'p-SoftwareTestCases',
  // 启用停用软件测试用例按钮
  B_ENABLEDISABLESOFTWARETESTTASEBUTTON = 'b-EnableDisableSoftwareTestTaseButton',
  // 新建软件测试用例页面
  P_NEWSOFTWARETESTCASEPAGE = 'p-NewSoftwareTestCasePage',
  // 编辑软件测试用例页面
  P_EDITSOFTWARETESTCASEPAGE = 'p-EditSoftwareTestCasePage',
  // 查看软件测试用例页面
  P_VIEWSOFTWARETESTCASEPAGE = 'p-ViewSoftwareTestCasePage',
  // 新建/编辑软件测试用例按钮
  B_ADDSOFTWARETESTCASEBUTTON = 'b-AddSoftwareTestCaseButton',
  // 测试用例删除按钮
  B_BATCHDELETIONOFSOFTWARETESTCASESBUTTON = 'b-BatchDeletionOfSoftwareTestCasesButton',

  // 软件测试任务工具3级
  P_SOFTWARETESTINGTASKTOOL = 'p-SoftwareTestingTaskTool',
  // 新建/编辑软件测试任务按钮
  B_NEWEDITSOFTWARETESTTASKBUTTON = 'b-NewEditSoftwareTestTaskButton',
  // 批量删除/删除软件测试任务按钮
  B_DELETESOFTWARETESTINGTASKBUTTON = 'b-DeleteSoftwareTestingTaskButton',
  // 新建软件任务基础信息
  P_NEWBASICSOFTWARETESTINGTASK = 'p-NewBasicSoftwareTestingTask',
  // 编辑软件任务基础信息
  P_EDITBASICSOFTWARETESTINGTASK = 'p-EditBasicSoftwareTestingTask',
  // 查看软件测试任务
  P_VIEWSOFTWARETESTINGTASK = 'p-ViewSoftwareTestingTask',
  // 新建测试任务测试用例
  P_NEWCASESOFTWARETESTINGTASK = 'p-NewCaseSoftwareTestingTask',
  // 编辑测试任务测试用例
  P_EDITCASESOFTWARETESTINGTASK = 'p-EditCaseSoftwareTestingTask',

  // 逆变器测试
  // 逆变器测试用例3级
  P_INVERTERTESTCASES = 'p-InverterTestCases',
  // 新建逆变器测试用例页面
  P_NEWINVERTERTESTCASES = 'p-NewInverterTestCases',
  // 启用停用逆变器测试用例按钮
  B_QTINVERTERTESTCASESBUTTON = 'b-QTInverterTestCasesButton',
  // 删除逆变器测试用例按钮
  B_DELETEINVERTERTESTCASESBUTTON = 'b-DeleteInverterTestCasesButton',
  // 新建/编辑逆变器测试用例按钮
  B_NEWEDITINVERTERTESTCASESBUTTON = 'b-NewEditInverterTestCasesButton',
  // 编辑逆变器测试用例页面
  P_EDITINVERTERTESTCASES = 'p-EditInverterTestCases',
  // 查看逆变器测试用例
  P_VIEWINVERTERTESTCASES = 'p-ViewInverterTestCases',

  // 逆变器测试任务工具3级
  P_INVERTERTESTCASESTASKTOOLS = 'p-InverterTestCasesTaskTools',
  // 编辑逆变器任务基础信息
  P_EDITBASICINVERTERTESTCASESTASKS = 'p-EditBasicInverterTestCasesTasks',
  // 新建/编辑逆变器测试任务按钮
  B_NEWEDITINVERTERTESTCASESTASKSBUTTON = 'b-NewEditInverterTestCasesTasksButton',
  // 删除逆变器测试任务按钮
  B_DELETEINVERTERTESTCASESTASKSBUTTON = 'b-DeleteInverterTestCasesTasksButton',
  // 新建逆变器任务基础信息
  P_NEWBASICINVERTERTESTCASESTASKS = 'p-NewBasicInverterTestCasesTasks',
  // 新建逆变器任务测试用例
  P_NEWCASESINVERTERTESTCASESTASKS = 'p-NewCasesInverterTestCasesTasks',
  // 编辑逆变器测试任务测试用例
  P_EDITCASESINVERTERTESTCASESTASKS = 'p-EditCasesInverterTestCasesTasks',
  // 查看逆变器测试任务
  P_VIEWINVERTERTESTCASESTASKS = 'p-ViewInverterTestCasesTasks',

  // 组串/组件
  //  组串/组件测试用例3级
  P_STRINGTESTCASE = 'p-StringTestCases',
  // 启用停用组串测试用例按钮
  B_ENABLEDISABLESTRINGTESTTASEBUTTON = 'b-EnableDisableStringTestTaseButton',
  // 新建/编辑组串测试用例按钮
  B_NEWEDITSTRINGTESTTASEBUTTON = 'b-NewEditStringTestTaseButton',
  // 删除组串测试用例按钮
  B_DELETESTRINGTESTTASEBUTTON = 'b-DeleteStringTestTaseButton',
  // 新建组串测试用例页面
  P_NEWSTRINGTESTTASE = 'p-NewStringTestTase',
  // 编辑组串测试用例页面
  P_EDITSTRINGTESTTASE = 'p-EditStringTestTase',
  // 查看组串测试用例页面
  P_VIEWSTRINGTESTTASE = 'p-ViewStringTestTase',

  // 组串测试任务工具3级
  P_STRINGTESTINGTASKTOOL = 'p-StringTestingTaskTool',
  // 新建/编辑组串测试任务按钮
  B_NEWEDITSTRINGTESTTASKBUTTON = 'b-NewEditStringTestTaskButton',
  // 删除组串测试任务按钮
  B_DELETESTRINGTESTTASKBUTTON = 'b-DeleteStringTestTaskButton',
  // 新建组串任务测试用例
  P_NEWCASESTRINGTESTTASK = 'p-NewCaseStringTestTask',
  // 编辑组串测试任务基础信息
  P_EDITBASICSTRINGTESTTASK = 'p-EditBasicStringTestTask',
  // 新建组串任务基础信息
  P_NEWBASICSTRINGTESTTASK = 'p-NewBasicStringTestTask',
  // 编辑组串任务测试用例
  B_EDITCASESTRINGTESTTASK = 'b-EditCaseStringTestTask',
  // 查看组串测试任务
  P_VIEWSTRINGTESTTASK = 'p-ViewStringTestTask',
}
