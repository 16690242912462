import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  CustomFilter,
  Dropdown,
  EllipsisSpan,
  Form,
  getRealUrl,
  Input,
  Key,
  Modal,
  Paging,
  Select,
  Table,
  Tag,
  useSearchParams,
  useUpdate,
  Wrapper,
} from '@maxtropy/components';
import {
  apiV2TestTaskCheckExecutionPost,
  apiV2TestTaskDeletePost,
  apiV2TestTaskGenerateWordPost,
  apiV2TestTaskPagePost,
  V2TestTaskPagePostRequest,
  V2TestTaskPagePostResponse,
} from '@maxtropy/pv-test-apis-v2';
import { useRequest } from 'ahooks';
import { MenuProps, Space } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StatusDisplay, StatusType } from '../InverterTestManagement/const';
import dayjs from 'dayjs';
import { CaseType, CaseTypeDisplay, useHasPermission } from '@/common/util';
import { HardwareTypeType, HardwareTypeTypeDisplay } from '../InverterTestCaseManagement/const';
import { PermissionsType } from '@/common/permissionsConst';
import { BreadCrumb } from '@maxtropy/rc-components';

interface SearchParams extends Omit<V2TestTaskPagePostRequest, 'page' | 'size'> {}
export type TestTaskPageItemProps = Exclude<V2TestTaskPagePostResponse['list'], undefined>[number];

const columns = [
  {
    title: '任务编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '任务名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '任务类型',
    dataIndex: 'type',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v ? CaseTypeDisplay[v as CaseType] : '--'} />,
  },
  {
    title: '硬件类型',
    dataIndex: 'hardwareType',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v ? HardwareTypeTypeDisplay[v as HardwareTypeType] : '--'} />,
  },
  {
    title: '样品名称',
    dataIndex: 'sampleName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '制造商',
    dataIndex: 'manufacture',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '送检日期',
    dataIndex: 'submissionDate',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD') : '--'} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (v: StatusType) => (
      <Tag border="solid" color={v === StatusType.FINISHED ? 'success' : '#4A90E2'}>
        {StatusDisplay[v]}
      </Tag>
    ),
  },
  {
    title: '最后编辑人',
    dataIndex: 'updateUser',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后编辑时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm') : '--'} />,
  },
];

interface FilterProps {
  name?: string;
  sampleName?: string;
  manufacture?: string;
  status?: number;
}

const ComponentTestManagement = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const pagingInfo = useSearchParams<SearchParams>();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [update, updateFn] = useUpdate();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const hasAddEditPermission = useHasPermission(PermissionsType.B_NEWEDITSTRINGTESTTASKBUTTON); // 新建/编辑
  const hasDeletePermission = useHasPermission(PermissionsType.B_DELETESTRINGTESTTASKBUTTON); // 删除
  const hasEditCasePermission = useHasPermission(PermissionsType.B_EDITCASESTRINGTESTTASK); // 编辑组串任务测试用例

  const { data, loading } = useRequest(
    () =>
      apiV2TestTaskPagePost({
        ...searchParams,
        type: CaseType.HARDWARE,
        hardwareType: HardwareTypeType.STRING,
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        setTotalCount(res.total ?? 0);
        return res.list;
      }),
    {
      refreshDeps: [searchParams, pageOffset, pageSize, update],
    }
  );

  const editRoutes = [
    {
      key: '1',
      isShow: hasAddEditPermission,
      route: `/testTools/componentTest/management/editBase`,
      title: '基础信息',
    },
    {
      key: '2',
      isShow: hasEditCasePermission,
      route: `/testTools/componentTest/management/editTask`,
      title: '测试任务',
    },
  ].filter(i => i.isShow);

  const viewRoutes = [
    {
      key: '1',
      isShow: true,
      route: `/testTools/componentTest/management/viewBase`,
      title: '基础信息',
    },
    {
      key: '2',
      isShow: true,
      route: `/testTools/componentTest/management/viewTask`,
      title: '测试任务',
    },
  ].filter(i => i.isShow);

  const getItems = (title: string, id: number): MenuProps['items'] => {
    if (title === '查看') {
      return viewRoutes.map(item => ({
        key: item.key,
        label: (
          <span
            onClick={() => {
              navigate(`${item.route}/${id}`);
            }}
          >
            {title + item.title}
          </span>
        ),
      }));
    } else {
      return editRoutes.map(item => ({
        key: item.key,
        label: (
          <span
            onClick={() => {
              navigate(`${item.route}/${id}`);
            }}
          >
            {title + item.title}
          </span>
        ),
      }));
    }
  };

  const onDelete = (ids: number[]) => {
    Modal.confirm({
      title: '确定删除所选用例吗？',
      onOk() {
        apiV2TestTaskDeletePost({ ids }).then(updateFn);
      },
    });
  };

  // 生成报告
  const createReport = (id: number) => {
    apiV2TestTaskCheckExecutionPost({ id }).then(res => {
      if (res) {
        apiV2TestTaskGenerateWordPost({ id }).then(_ => {
          updateFn();
        });
      } else {
        Modal.confirm({
          title: `用例未全部执行，是否继续生成报告？`,
          onOk: () =>
            apiV2TestTaskGenerateWordPost({ id }).then(_ => {
              updateFn();
            }),
        });
      }
    });
  };

  // 下载报告
  const downLoadReport = (fileKey: string) => {
    window.open(getRealUrl(fileKey), '__blank');
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 210,
      fixed: 'right' as 'right',
      render: (value: undefined, record: TestTaskPageItemProps) => {
        return (
          <Space size={16}>
            {hasDeletePermission && (
              <Button type="link" onClick={() => onDelete([record.id!])}>
                删除
              </Button>
            )}
            <Dropdown type="link" menu={{ items: getItems('查看', record.id!) }}>
              <a href="/" onClick={e => e.preventDefault()}>
                查看
              </a>
            </Dropdown>
            {record.status === StatusType.TESTING && (
              <Dropdown type="link" menu={{ items: getItems('编辑', record.id!) }}>
                <a href="/" onClick={e => e.preventDefault()}>
                  编辑
                </a>
              </Dropdown>
            )}
            {record.fileKey && record.status === StatusType.FINISHED ? (
              <Button type="link" onClick={() => downLoadReport(record.fileKey!!)}>
                下载报告
              </Button>
            ) : (
              <Button type="link" onClick={() => createReport(record.id!)}>
                生成报告
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const onFinish = (v: FilterProps) => {
    setSearchParams({ ...v, type: CaseType.HARDWARE, hardwareType: HardwareTypeType.STRING });
    setPageOffset(1);
  };

  const onReset = () => {
    const params = {
      name: undefined,
      sampleName: undefined,
      manufacture: undefined,
      status: undefined,
    };
    setSearchParams({ ...params, type: CaseType.HARDWARE, hardwareType: HardwareTypeType.STRING });
    setPageOffset(1);
  };

  const filters = (
    <CustomFilter form={form} onFinish={v => onFinish(v as FilterProps)} onReset={onReset} collapseOpen={false}>
      <Form.Item label="任务名称/编号" name="name">
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item label="样品名称" name="sampleName">
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item label="制造商" name="manufacture">
        <Input placeholder="请输入" />
      </Form.Item>

      <Form.Item label="状态" name="status">
        <Select
          options={Object.entries(StatusDisplay).map(([k, v]) => ({
            label: v,
            value: Number(k),
          }))}
          placeholder="请选择"
        />
      </Form.Item>
    </CustomFilter>
  );

  const rowSelection = {
    preserveSelectedRowKeys: true,
    onChange: (rowKeys: Key[]) => {
      setSelectedRows(rowKeys as number[]);
    },
    selectedRowKeys: selectedRows,
  };

  return (
    <Wrapper filters={filters} breadCrumb={<BreadCrumb />}>
      <Space size={8} style={{ marginBottom: 10 }}>
        {hasAddEditPermission && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate(`/testTools/componentTest/management/createBase`)}
          >
            添加测试任务
          </Button>
        )}
        <Button type="primary" disabled={selectedRows.length === 0} onClick={() => onDelete(selectedRows)}>
          批量删除
        </Button>
      </Space>
      <Table
        columns={buildColumns}
        dataSource={data}
        rowKey="id"
        loading={loading}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
      />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default ComponentTestManagement;
