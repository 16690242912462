import React from 'react';
import {  RouterProvider } from 'react-router-dom';
import './App.scss';
import { CustomApp, useThemeContext } from '@maxtropy/components';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import routers from './routes'


function App() {
  const theme = useThemeContext();

  return (
    <div className="App">
      <ConfigProvider locale={zhCN} theme={theme} space={{ size: 3 }}>
        <CustomApp style={{ height: '100%' }}>
          <RouterProvider router={routers}/>
        </CustomApp>
      </ConfigProvider>
    </div>
  );
}

export default App;
