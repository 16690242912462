import { AbilityDomainType, IntelligentLevel, IntelligentLevelDisplay, StatusType } from '@/common/util';
import { EllipsisSpan, Tag } from '@maxtropy/components';
import { V2TestTaskCasePagePostResponse } from '@maxtropy/pv-test-apis-v2';
import { ColumnsType } from 'antd/es/table';
import styles from './index.module.scss';

export type DataType = Exclude<V2TestTaskCasePagePostResponse['list'], undefined>[number];

export const baseColumns: ColumnsType<DataType> = [
  {
    title: '用例编号',
    dataIndex: 'caseCode',
    render: v => <EllipsisSpan value={v} />,
    width: 120,
  },
  {
    title: '用例名称',
    dataIndex: 'name',
    width: 120,
    render: v => <EllipsisSpan className={styles.overFlowStyle} value={v} />,
  },
  {
    title: '所属能力域',
    dataIndex: 'abilityDomainDesc',
    width: 120,
    render: (v: AbilityDomainType) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属智能级',
    dataIndex: 'intelligentLevel',
    width: 100,
    render: (v, record) => <EllipsisSpan value={IntelligentLevelDisplay[v as IntelligentLevel]} />,
  },
];

export const buildColumns: ColumnsType<DataType> = [
  ...baseColumns,
  {
    title: '状态',
    width: 50,
    dataIndex: 'status',
    render: v => (
      <EllipsisSpan
        value={
          v === StatusType.ENABLE ? (
            <Tag border="solid" color="success">
              启用
            </Tag>
          ) : (
            <Tag border="solid" color="default">
              停用
            </Tag>
          )
        }
      />
    ),
  },
  {
    title: '版本',
    width: 50,
    dataIndex: 'versionNo',
    render: (v, record) => (
      <EllipsisSpan
        value={<div>{!record.latestVersion ? <span style={{ color: 'var(--mx-warning-color)' }}>{v}*</span> : v}</div>}
      />
    ),
  },
];
