import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Divider, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { useRequest } from 'ahooks';
import { isEmpty, isNil } from 'lodash';
import {
  Button,
  EllipsisSpan,
  Form,
  FormTitle,
  Modal,
  Paging,
  Select,
  SubContent,
  Table,
  usePaging,
  useUpdate,
  Wrapper,
} from '@maxtropy/components';
import {
  apiV2TestTaskAssessmentPost,
  apiV2TestTaskCaseLatestInfoPost,
  apiV2TestTaskCasePagePost,
  apiV2TestTaskDeleteCasePost,
  apiV2TestTaskSaveCasePost,
} from '@maxtropy/pv-test-apis-v2';
import SelectCaseModal from './components/SelectedCaseModal';
import { LevelType, LevelTypeDisplay, StatusType } from '@/common/util';
import { DataType, buildColumns } from './util';
import SwitchVersion from './components/SwitchVersion';
import IntelligentEvaluationCriteria from './components/IntelligentEvaluationCritria';
import styles from './index.module.scss';
import UploadAssess from '@/components/UploadAssess';
import UploadProve from '@/components/UploadProve';
import { BreadCrumb } from '@maxtropy/rc-components';

interface AddTaskCaseProps {
  isEdit?: boolean;
}

const AddTaskCase: React.FC<AddTaskCaseProps> = ({ isEdit }) => {
  const navigate = useNavigate();
  const { id: taskId } = useParams<{ id: string }>();

  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [update, updateFn] = useUpdate();
  const [selectedCase, setSelectedCase] = useState<number>();
  const [openCase, setOpenCase] = useState<boolean>();
  const [openSwitchVersion, setOpenSwitchVersion] = useState<boolean>();
  const [selectedId, setSelectedId] = useState<number>();
  const [openProveModal, setOpenProveModal] = useState<boolean>();
  const [openAssessModal, setOpenAssessModal] = useState<boolean>();
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);

  const [form] = Form.useForm();

  const { data, loading } = useRequest(
    () =>
      apiV2TestTaskCasePagePost({
        taskId: Number(taskId),
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        setTotalCount(res.total ?? 0);
        form.setFieldValue('list', res.list ?? []);
        return res.list || [];
      }),
    {
      refreshDeps: [update],
    }
  );

  const { data: assessmentData } = useRequest(() => apiV2TestTaskAssessmentPost({ id: taskId }), {
    refreshDeps: [taskId, update],
  });

  const { data: caseLatestInfo } = useRequest(() => apiV2TestTaskCaseLatestInfoPost({ id: taskId }), {
    refreshDeps: [taskId, update],
  });

  const delSelectedCase = (id: number) => {
    if (isNil(id)) return;
    apiV2TestTaskDeleteCasePost({ id }).then(() => {
      updateFn();
    });
  };

  const columns: ColumnsType<DataType> = [
    ...buildColumns,
    {
      title: '标准要求',
      width: 150,
      dataIndex: 'standardRequirement',
      render: (v, record) => {
        const content = record.standardRequirement?.map(item => {
          return (
            <>
              <span style={{ fontWeight: 'bold' }}>
                {LevelTypeDisplay[item.level as LevelType]}（{item.score}分）
              </span>
              {item.desc ?? ''}；
            </>
          );
        });
        return (
          <EllipsisSpan
            className={styles.overFlowStyle}
            value={isEmpty(record.standardRequirement) ? undefined : content}
          />
        );
      },
    },
    {
      title: '测试步骤',
      width: 260,
      dataIndex: 'testProcedure',
      render: v => <EllipsisSpan className={styles.overFlowStyle} value={v} />,
    },
    {
      title: '结果',
      dataIndex: 'testResult',
      fixed: 'right' as const,
      width: 150,
      render: (v, record, index) => {
        return (
          <Form.Item name={['list', index, 'testResult']} noStyle>
            <Select
              placeholder="请选择"
              style={{ width: '100%' }}
              onChange={value => {
                apiV2TestTaskSaveCasePost({ id: record.id, testResult: value }).then(updateFn);
              }}
              options={record.standardRequirement?.map(item => ({
                label: `${LevelTypeDisplay[item.level as LevelType]}（${item.score ?? 0}分）`,
                value: item.level,
              }))}
            />
          </Form.Item>
        );
      },
    },
    {
      title: '操作',
      dataIndex: 'operation',
      width: 200,
      fixed: 'right' as const,
      render: (v, record) => (
        <Space size={16}>
          <Button
            type="link"
            onClick={() => {
              Modal.confirm({
                title: '确认删除所选用例？',
                onOk: () => delSelectedCase(record.id!),
              });
            }}
          >
            删除
          </Button>
          <Button
            type="link"
            onClick={() => {
              setSelectedId(record.id);
              setSelectedCase(record.caseId);
              setOpenSwitchVersion(true);
            }}
          >
            切换版本
          </Button>
          <Button
            type="link"
            disabled={isNil(record.fileKeys)}
            onClick={() => window.open(`/testTasks/management/viewFile/${record.id}`, '__blank')}
          >
            查看资料
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Wrapper breadCrumb={<BreadCrumb options={[{ name: '编辑任务测试用例' }]} />}>
      <Form isOrigin={true} form={form} layout="horizontal">
        <FormTitle title="编辑任务测试用例" />
        <SubContent>
          <Space size={10} style={{ marginBottom: 10 }}>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => setOpenCase(true)}>
              选择用例
            </Button>
            <Button
              type="primary"
              onClick={() => window.open(`/api/v2/test-task/downloadSelfEvaluationForm?id=${taskId}`)}
            >
              导出自评表
            </Button>
            <Button type="primary" onClick={() => setOpenAssessModal(true)}>
              导入自评结果
            </Button>
            <Button type="primary" onClick={() => setOpenProveModal(true)}>
              导入证明材料
            </Button>
            <div>
              <span style={{ color: 'var(--mx-error-color)', marginRight: 4 }}>
                {caseLatestInfo?.stoppedCount ?? 0}
              </span>
              条用例已停用，
              <span style={{ color: 'var(--mx-warning-color)', marginRight: 4 }}>
                {caseLatestInfo?.updatingCount ?? 0}
              </span>
              条用例版本有更新（对应用例版本号已标星<span style={{ color: 'var(--mx-warning-color)' }}>*</span> ）
            </div>
          </Space>
          <Form.Item name="list" valuePropName="dataSource">
            <Table
              rowKey="id"
              columns={columns}
              // dataSource={data}
              loading={loading}
              scroll={{ x: 600 }}
            />
          </Form.Item>
          <Paging pagingInfo={pagingInfo} />

          {/* 测试结果 */}
          <IntelligentEvaluationCriteria data={assessmentData} />
          <Space size={8} className={'sticky-footer-left'}>
            <Button wrapStyle={{ marginTop: 32 }} onClick={() => navigate(-1)}>
              返回
            </Button>
          </Space>
        </SubContent>
      </Form>
      {openCase && <SelectCaseModal onCancel={() => setOpenCase(false)} updateFn={updateFn} />}
      {openSwitchVersion && (
        <SwitchVersion
          selectedId={selectedId}
          caseId={selectedCase}
          onCancel={() => setOpenSwitchVersion(false)}
          updateFn={updateFn}
        />
      )}
      {openProveModal && <UploadProve taskId={taskId} setOpen={setOpenProveModal} updateFn={updateFn} />}
      {openAssessModal && <UploadAssess taskId={taskId} setOpen={setOpenAssessModal} updateFn={updateFn} />}
    </Wrapper>
  );
};

export default AddTaskCase;
