import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getRealUrl } from '@maxtropy/components';
import { isNil } from 'lodash';
import { apiV2TestTaskFileKeysPost } from '@maxtropy/pv-test-apis-v2';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

const ViewFile: React.FC = () => {
  const { id: caseId } = useParams<{ id: string }>();
  const [currentFileKey, setCurrentFileKey] = useState<string>();
  const [fileKeys, setFileKeys] = useState<string[]>([]);
  const [fileUrl, setFileUrl] = useState<string>();
  const [fileName, setFileName] = useState<string>();

  useEffect(() => {
    if (isNil(caseId)) return;

    apiV2TestTaskFileKeysPost({ id: Number(caseId) }).then(res => {
      setFileKeys(res.fileKeys ?? []);

      const key = res.fileKeys?.[0];
      if (key) {
        setCurrentFileKey(key);
        const name = key.split('/').pop() || '';
        setFileName(name);
        if (name.endsWith('pdf') || name.endsWith('PDF')) {
          loadPDFIntoIframe(getRealUrl(key)!);
          setFileUrl(undefined);
        } else {
          setFileUrl(getRealUrl(key));
        }
      }
    });
  }, [caseId]);

  const prev = () => {
    if (fileKeys.length > 1) {
      const index = fileKeys.findIndex(item => item === currentFileKey);
      const key = fileKeys[index - 1];
      setCurrentFileKey(key);
      const name = key.split('/').pop() || '';
      setFileName(name);
      if (name.endsWith('pdf') || name.endsWith('PDF')) {
        loadPDFIntoIframe(getRealUrl(key)!);
        setFileUrl(undefined);
      } else {
        setFileUrl(getRealUrl(key));
      }
    }
  };

  const next = () => {
    if (fileKeys.length > 1) {
      const index = fileKeys.findIndex(item => item === currentFileKey);

      const key = fileKeys[index + 1];

      setCurrentFileKey(key);
      const name = key.split('/').pop() || '';
      setFileName(name);
      if (name.endsWith('pdf') || name.endsWith('PDF')) {
        loadPDFIntoIframe(getRealUrl(key)!);
        setFileUrl(undefined);
      } else {
        setFileUrl(getRealUrl(key));
      }
    }
  };

  const fileIndex = useMemo(() => {
    console.log('222', fileKeys);
    console.log('444', currentFileKey);
    return fileKeys.findIndex(item => item === currentFileKey);
  }, [currentFileKey, fileKeys]);

  const loadPDFIntoIframe = async (apiUrl: string) => {
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
        },
      });

      if (response.ok) {
        const blob = await response.blob();
        const pdfUrl = URL.createObjectURL(blob);
        const iframe = document.getElementById('pdfIframe');
        if (iframe) {
          // @ts-ignore
          iframe.src = pdfUrl;
          // 释放 URL 防止内存泄漏（可在 iframe 加载完成或页面卸载时调用）
          iframe.onload = () => URL.revokeObjectURL(pdfUrl);
        }
      } else {
        console.error('无法获取 PDF 文件:', response.statusText);
      }
    } catch (error) {
      console.error('请求失败:', error);
    }
  };

  return (
    <div className={styles.viewWrapper}>
      <div className={styles.content}>
        <div className={styles.left}>
          {fileIndex === 0 ? null : (
            <div className={styles.icon} onClick={prev}>
              <LeftOutlined style={{ color: 'var(--mx-text-base-color)', fontSize: 24 }} />
            </div>
          )}
        </div>

        <div className={styles.middle}>
          <div className={styles.fileName}>{fileName}</div>
          <div className={styles.fileContent}>
            {fileUrl ? (
              <img src={fileUrl} className={styles.img}></img>
            ) : (
              <iframe id="pdfIframe" width="100%" height="800px" style={{ borderWidth: 0 }}></iframe>
            )}
          </div>
        </div>
        <div className={styles.right}>
          {fileIndex === fileKeys.length - 1 ? null : (
            <div className={styles.icon} onClick={next}>
              <RightOutlined style={{ color: 'var(--mx-text-base-color)', fontSize: 24 }} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewFile;
