import React, { useEffect, useMemo, useState } from 'react';
import { Upload } from 'antd';
import { RcFile, UploadChangeParam, UploadProps } from 'antd/es/upload';
import { UploadFileStatus } from 'antd/es/upload/interface';
import { UploadOutlined, ContainerOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/es/upload/interface';
import { PlusOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash-es';
import classnames from 'classnames';
import { getRealUrl, FILE_TYPE as FileType, getFileSuffix } from './util';
import './index.scss';
import useFileList from './useFileList';
import { Button, Modal } from '@maxtropy/components';

const Dragger = Upload.Dragger;

type FileSpec = { width?: number; height?: number };

type LocalFileProps = string | string[];

function responseToFileList(value: string, fileList: UploadFile[]): UploadFile {
  if (fileList.length) {
    // 如果在fileList存在，则为本次新上传的文件，不用做默认值处理
    const fileIndex = fileList.findIndex(file => (file.response?.data || file.url) === value);
    if (fileIndex > -1) {
      return fileList[fileIndex];
    }
  }

  const url = getRealUrl(value);
  const arr = url?.split('-') || [];
  const name = arr?.[arr.length - 1] || '';

  return {
    name: name,
    url,
    uid: value,
    status: 'done',
  } as UploadFile;
}

const checkImgSize = (file: UploadFile, fileSpec: FileSpec) => {
  const { width, height } = fileSpec;
  return new Promise((resolve, reject) => {
    let _URL = window.URL || window.webkitURL;
    let img = new Image();
    img.onload = function () {
      let valid = true;
      if ((width && img.width !== width) || (height && img.height !== height)) {
        valid = false;
      }
      valid ? resolve(true) : resolve(false);
    };
    img.src = _URL.createObjectURL(file as unknown as Blob);
  });
};

export interface MXUploadProps extends Omit<UploadProps, 'accept' | 'onChange'> {
  value?: LocalFileProps;
  onChange?: (val?: LocalFileProps, status?: UploadFileStatus) => void;
  fileSize?: number;
  fileSpec?: FileSpec;
  accept?: Array<string>;
  action?: string;
  tip?: string;
  name?: string;
  isDragger?: boolean;
  uploadText?: string;
  children?: any;
  onUploadStatus?: (status?: UploadFileStatus) => void;
}

const MXUpload = (props: MXUploadProps) => {
  const {
    listType = props.isDragger ? 'text' : 'picture-card',
    value,
    onChange,
    accept = ['.jpg', '.jpeg', '.png'],
    fileSize = 1,
    maxCount = 1,
    tip,
    action = '/api/file-center/upload',
    uploadText,
    fileSpec,
    isDragger,
    children,
    onUploadStatus,
    multiple = false,
    ...restProps
  } = props;

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | undefined>('');
  const [previewImageName, setPreviewImageName] = useState<string>();
  const [errorMsg, setErrorMsg] = useState<string[]>([]);
  const [fileList, transFileList] = useFileList([]);

  useEffect(() => {
    // value是FormItem包裹时注入的，onChange的值对应value值
    console.log('value', value);
    if (value) {
      if (Array.isArray(value)) {
        const files: UploadFile[] = value.map(i => responseToFileList(i, fileList));
        transFileList(files);
      } else {
        transFileList([responseToFileList(value, fileList)]);
      }
    } else {
      transFileList([]);
    }
    // 添加value依赖是为了处理组件已经加载，但是接口数据还没有返回的情况
  }, [value]);

  const doneNum = useMemo(() => {
    return fileList.filter(i => i.status !== 'uploading').length;
  }, [fileList]);

  useEffect(() => {
    if (fileList.length > 0) {
      if (doneNum === fileList.length) {
        return onUploadStatus?.('done');
      }
      onUploadStatus?.('uploading');
    } else {
      onUploadStatus?.(undefined);
    }
  }, [doneNum, fileList.length]);

  useEffect(() => {
    const errMsg = document.querySelector(`.mx-upload-text.mx-upload-${props.id} .mx-error-msg`) as HTMLElement;
    const uploadSelect = document.querySelector(
      `.mx-upload-text.mx-upload-${props.id} .ant-upload-select`
    ) as HTMLElement;
    const uploadDragger = document.querySelector(
      `.mx-upload-text.mx-upload-${props.id} .ant-upload-drag`
    ) as HTMLElement;

    if (uploadSelect) {
      const tipMsg = document.querySelector(`.mx-upload-text.mx-upload-${props.id} .mx-upload-tip`) as HTMLElement;

      uploadSelect.style.marginBottom = `${(tipMsg?.offsetHeight || 0) + (errMsg?.offsetHeight || 0)}px`;

      if (errMsg) {
        errMsg.style.position = 'absolute';
        errMsg.style.top = `${uploadSelect.offsetHeight + (tipMsg?.offsetHeight || 0)}px`;
      }
    }

    if (uploadDragger) {
      uploadDragger.style.marginBottom = `${errMsg?.offsetHeight || 0}px`;

      if (errMsg) {
        errMsg.style.position = 'absolute';
        errMsg.style.top = `${uploadDragger.offsetHeight}px`;
      }
    }
  }, [errorMsg]);

  const beforeUpload = async (info: RcFile) => {
    const { name } = info;
    const limitFileSize = info.size / 1024 / 1024 <= fileSize;
    const limitFileType = accept ? accept.includes(getFileSuffix(name)) : true;
    let msg = [];
    let resultBoolean = true;

    if (!limitFileSize) {
      resultBoolean = false;
      msg.push(`上传文件的大小不得超过${fileSize}M`);
    }
    if (!limitFileType) {
      resultBoolean = false;
      msg.push(`请上传正确的文件格式(${accept})`);
    }

    if (fileSpec) {
      const limitFileSpec = await checkImgSize(info, fileSpec);

      if (!limitFileSpec) {
        resultBoolean = false;
        msg.push(`请上传符合要求的尺寸`);
      }
    }

    setErrorMsg(msg);

    return resultBoolean ? Promise.resolve(new File([info], info.name)) : false;
  };

  const handleChange = (info: UploadChangeParam) => {
    const { status } = info.file;

    console.log('gggg');
    // beforeUpload 拦截的文件没有 status 状态属性
    if (status) {
      if (status === 'done') {
        if (maxCount === 1) {
          const key: string = info.file.response.data;
          onChange?.(key, status);
        } else {
          console.log('kkk', info);
          const list: string[] = info.fileList.map(i => i.response?.data || i.uid);

          console.log('kkk', list);
          onChange?.(list, status);
        }

        setErrorMsg([]);
      }

      if (status === 'removed') {
        if (maxCount === 1) {
          onChange?.(undefined, status);
        } else {
          const fileList: string[] = info.fileList.map(i => i.response?.data || i.uid);
          onChange?.(fileList, status);
        }
      }

      // 文件url需要getRealUrl转换一下，picture-card上传完要能看到图片
      transFileList(info.fileList);
    }
  };

  const uploadButton = () => {
    const disabled = fileList.length >= maxCount;

    switch (listType) {
      // 目前设计只考虑text和picture-card两种类型
      case 'text':
        return (
          <Button disabled={disabled} icon={<UploadOutlined />}>
            {uploadText || '上传文件'}
          </Button>
        );

      case 'picture-card':
        return fileList.length >= maxCount ? null : (
          <>
            <PlusOutlined />
            <div>{uploadText || '上传图片'}</div>
          </>
        );
    }
  };

  const uploadDom = (newProps: Record<string, any>) => {
    return (
      <>
        <Upload {...newProps}>{children ?? uploadButton()}</Upload>
        {!children && tip && <div className="mx-upload-tip">{tip}</div>}
        {!isEmpty(errorMsg) && (
          <div className="mx-error-msg">
            {errorMsg?.map(msg => (
              <p>{msg}</p>
            ))}
          </div>
        )}
      </>
    );
  };

  const draggerDom = (newProps: Record<string, any>) => {
    const disabled = fileList.length >= maxCount;

    return (
      <div className="mx-upload-dragger">
        <Dragger disabled={disabled} {...newProps}>
          <p className="mx-upload-drag-icon">
            <ContainerOutlined />
          </p>
          <p className="mx-upload-text">点击或将文件拖拽到这里上传</p>
          <p className="mx-upload-hint">{tip}</p>
        </Dragger>
        {!isEmpty(errorMsg) && (
          <div className="mx-error-msg">
            {errorMsg?.map(msg => (
              <p>{msg}</p>
            ))}
          </div>
        )}
      </div>
    );
  };

  const handlePreview = (file: UploadFile) => {
    const fileSuffix = getFileSuffix(file.url!);
    // 图片预览，非图片格式的点击下载
    if (FileType.IMAGE.includes(fileSuffix)) {
      setPreviewImage(file.url);
      setPreviewImageName(file.originFileObj?.name ? file.originFileObj.name.split('.')[0] : '查看图片');
      setPreviewOpen(true);
    } else {
      window.open(file.url);
    }
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
    setPreviewImage(undefined);
    setPreviewImageName(undefined);
  };

  const getUploadComponent = () => {
    const newProps = {
      accept: accept.join(','),
      action,
      fileList,
      onPreview: handlePreview,
      onChange: handleChange,
      beforeUpload,
      maxCount,
      multiple,
      listType,
      ...restProps,
    };

    if (isDragger) {
      return draggerDom(newProps);
    } else {
      return uploadDom(newProps);
    }
  };

  const cls = classnames({
    'mx-upload': true,
    [`mx-upload-${listType}`]: true,
    [`mx-upload-${props.id}`]: true,
  });

  return (
    <>
      <div className={cls}>{getUploadComponent()}</div>
      <Modal
        className="mx-upload-preview"
        title={previewImageName}
        open={previewOpen}
        footer={() => (
          <Button type="primary" onClick={handleClosePreview}>
            确定
          </Button>
        )}
        onCancel={handleClosePreview}
      >
        <img alt="preview" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};

export const FILE_TYPE = FileType;

export default MXUpload;
