import {
  Button,
  EllipsisSpan,
  Input,
  Table,
  Wrapper,
  useSearchParams,
  useUpdate,
  Tag,
  Paging,
  CustomFilter,
  Form,
  Select,
  Modal,
} from '@maxtropy/components';
import { Cascader, Space } from 'antd';
import { useRequest } from 'ahooks';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';
import {
  CaseType,
  IntelligentLevel,
  IntelligentLevelDisplay,
  StatusDisplay,
  StatusType,
  intelligentLevelOptions,
  statusOptions,
  useHasPermission,
} from '@/common/util';
import {
  V2TestCasePagePostRequest,
  apiV2TestCaseDeletePost,
  apiV2TestCasePagePost,
  apiV2TestCaseSoftwareAbilityDomainListPost,
  apiV2TestCaseSwitchStartOrStopPost,
} from '@maxtropy/pv-test-apis-v2';
import { useNavigate } from 'react-router-dom';
import { isNil } from 'lodash';
import { PermissionsType } from '@/common/permissionsConst';
import { BreadCrumb } from '@maxtropy/rc-components';

const columns = [
  {
    title: '用例编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '用例名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '用例类型',
    dataIndex: 'type',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={'软件测试'} />,
  },
  {
    title: '所属能力域',
    dataIndex: 'abilityDomainDisplay',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属智能级',
    dataIndex: 'intelligentLevel',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={IntelligentLevelDisplay[v as IntelligentLevel]} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (v: StatusType) => (
      <Tag border="solid" color={v === StatusType.ENABLE ? 'success' : 'default'}>
        {StatusDisplay[v]}
      </Tag>
    ),
  },
  {
    title: '当前版本号',
    dataIndex: 'versionNo',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后编辑人',
    dataIndex: 'createUser',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后编辑时间',
    dataIndex: 'createTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm')} />,
  },
];

interface SearchParams extends Omit<V2TestCasePagePostRequest, 'page' | 'size' | 'abilityDomain'> {
  abilityDomain?: number[];
}

const TestCases: React.FC = () => {
  const [form] = Form.useForm();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const navigate = useNavigate();
  const pagingInfo = useSearchParams<SearchParams>(20);
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;
  const [update, updateFn] = useUpdate();
  const [tableData, setTableData] = useState<any[]>([]);
  const hasEnablePermission = useHasPermission(PermissionsType.B_ENABLEDISABLESOFTWARETESTTASEBUTTON); // 启用/停用
  const hasAddPermission = useHasPermission(PermissionsType.B_ADDSOFTWARETESTCASEBUTTON); // 新建/编辑
  const hasDelPermission = useHasPermission(PermissionsType.B_BATCHDELETIONOFSOFTWARETESTCASESBUTTON); // 删除

  const { data: softwareAbilityDomainData } = useRequest(
    () => apiV2TestCaseSoftwareAbilityDomainListPost().then(res => res.list ?? []),
    {
      refreshDeps: [],
    }
  );

  const { data, loading } = useRequest(
    () =>
      apiV2TestCasePagePost({
        ...searchParams,
        type: CaseType.SOFTWARE,
        abilityDomain: !isNil(searchParams?.abilityDomain?.[1]) ? [searchParams?.abilityDomain?.[1]!] : undefined,
        intelligentLevel: !isNil(searchParams.intelligentLevel?.[0])
          ? [searchParams.intelligentLevel?.[0]!]
          : undefined,
        page: searchParams.pageOffset,
        size: searchParams.pageSize,
      }).then(res => {
        setTotalCount(res.total ?? 0);
        return res.list;
      }),
    {
      refreshDeps: [searchParams, setTotalCount, update],
    }
  );

  const findNamesByAbilityId = (list: any[], abilityId: number) => {
    for (const item of list) {
      for (const ability of item.abilityList) {
        if (ability.id === abilityId) {
          return `${item.name}/${ability.name}`;
        }
      }
    }
    return null; // 如果未找到，返回 null
  };

  useEffect(() => {
    if (isNil(data) || isNil(softwareAbilityDomainData)) return;

    const list = data.map(m => ({
      ...m,
      abilityDomainDisplay: findNamesByAbilityId(softwareAbilityDomainData, m.abilityDomain!),
    }));
    setTableData(list);
  }, [softwareAbilityDomainData, data]);

  // 启用/禁用
  const onEnable = (id: number, status: StatusType) => {
    if (isNil(id)) return;

    if (status === StatusType.ENABLE) {
      Modal.confirm({
        title: '确定停用该测试用例吗？',
        onOk() {
          apiV2TestCaseSwitchStartOrStopPost({
            id,
          }).then(updateFn);
        },
      });
    } else {
      apiV2TestCaseSwitchStartOrStopPost({
        id,
      }).then(updateFn);
    }
  };

  const onDelete = (idList: number[]) => {
    const ids = idList.filter(m => !isNil(m));
    if (ids.length === 0) return;
    Modal.confirm({
      title: '确定删除所选用例吗？',
      onOk() {
        apiV2TestCaseDeletePost({ caseIds: ids }).then(updateFn);
      },
    });
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedIds(selectedRows.map(k => k.caseId as number));
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.status === StatusType.ENABLE,
    }),
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 191,
      fixed: 'right' as 'right',
      render: (value: any, record: any) => {
        return (
          <Space size={16}>
            {hasDelPermission && (
              <Button
                type="link"
                onClick={() => onDelete([record.caseId])}
                disabled={record.status === StatusType.ENABLE}
              >
                删除
              </Button>
            )}

            <Button type="link" onClick={() => navigate(`/testCases/management/list/view/${record.caseId}`)}>
              查看
            </Button>
            {hasAddPermission && (
              <Button
                type="link"
                disabled={record.status === StatusType.ENABLE}
                onClick={() => navigate(`/testCases/management/list/edit/${record.id}`)}
              >
                编辑
              </Button>
            )}

            {hasEnablePermission && (
              <Button type="link" onClick={() => onEnable(record.caseId, record.status)}>
                {record.status === StatusType.ENABLE ? '停用' : '启用'}
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const onFinish = (v: SearchParams) => {
    finish(v);
  };

  const onReset = () => {
    reset({});
  };

  const filters = (
    <CustomFilter form={form} onFinish={onFinish} onReset={onReset} collapseOpen={false}>
      <Form.Item label="用例名称/编号" name="name">
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item label="所属能力域" name="abilityDomain">
        <Cascader
          options={softwareAbilityDomainData}
          fieldNames={{ label: 'name', value: 'id', children: 'abilityList' }}
          placeholder="请选择"
        />
      </Form.Item>
      <Form.Item label="所属智能级" name="intelligentLevel">
        <Select options={intelligentLevelOptions} placeholder="请选择" />
      </Form.Item>
      <Form.Item label="状态" name="status">
        <Select options={statusOptions} placeholder="请选择" />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper filters={filters} breadCrumb={<BreadCrumb />}>
      <Space size={8} style={{ marginBottom: 10 }}>
        {hasAddPermission && (
          <Button type="primary" icon={<PlusOutlined />} onClick={() => navigate(`/testCases/management/list/add`)}>
            添加测试用例
          </Button>
        )}

        <Button type="primary" disabled={selectedIds.length == 0} onClick={() => onDelete(selectedIds)}>
          批量删除
        </Button>
      </Space>
      <Table
        columns={buildColumns}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        dataSource={tableData}
        rowKey="id"
        loading={loading}
      />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default TestCases;
