export enum AbilityDomainType {
  HARDWARE_PERFORMANCE = 17, // 硬件性能
  INTELLIGENT_CONTROL = 18, // 智能控制
  DATA_TRANSMISSION = 19, // 数据传输
}

export const AbilityDomainDisplay = {
  [AbilityDomainType.HARDWARE_PERFORMANCE]: '硬件性能',
  [AbilityDomainType.INTELLIGENT_CONTROL]: '智能控制',
  [AbilityDomainType.DATA_TRANSMISSION]: '数据传输',
};

export enum CaseType {
  SOFTWARE = 1,
  HARDWARE,
}

export const CaseTypeDisplay = {
  [CaseType.SOFTWARE]: '软件测试',
  [CaseType.HARDWARE]: '硬件测试',
};

export enum HardwareTypeType {
  STRING = 1,
  INVERTER,
}

export const HardwareTypeTypeDisplay = {
  [HardwareTypeType.STRING]: '组串/组件',
  [HardwareTypeType.INVERTER]: '逆变器',
};

export enum JudgmentMethod {
  ARTIFICIAL = 1, // 人工判断
  SYSTEM = 2, // 系统计算
}

export const JudgmentMethodDisplay = {
  [JudgmentMethod.ARTIFICIAL]: '人工判断',
  [JudgmentMethod.SYSTEM]: '系统计算',
};

export enum StatusType {
  DISABLE = 0, // 停用
  ENABLE = 1, // 启用
}

export const StatusDisplay = {
  [StatusType.DISABLE]: '停用',
  [StatusType.ENABLE]: '启用',
};

export enum ReferentType {
  CONTROL_GROUP = 1, // 对照组
  THEROETICAL_VALUE, // 理论值
}

export const ReferentTypeDisplay = {
  [ReferentType.CONTROL_GROUP]: '对照组',
  [ReferentType.THEROETICAL_VALUE]: '理论值',
};

// 正负
export enum DeviationType {
  PLUS_MINUS = 0, // 正负
  PLUS, // 正
  MINUS, // 负
}

export const DeviationTypeDisplay = {
  [DeviationType.PLUS_MINUS]: '±',
  [DeviationType.PLUS]: '+',
  [DeviationType.MINUS]: '-',
};

// 等级
export enum LevelType {
  A = 1, // A类
  B, // B类
  C, // C类
  D, // D类
}

export const LevelTypeDisplay = {
  [LevelType.A]: 'A类',
  [LevelType.B]: 'B类',
  [LevelType.C]: 'C类',
  [LevelType.D]: 'D类',
};
