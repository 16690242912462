import { useState } from 'react';
import { UploadFile } from 'antd/es/upload/interface';
import { getRealUrl } from './util';

const getRealFile = (file: UploadFile) => {
  return {
    ...file,
    url: file.response?.key ? getRealUrl(file.response.key) : file.url,
  };
};

const useFileList = (initialValue: UploadFile[]) => {
  const [fileList, setFileList] = useState<UploadFile[]>(initialValue);

  function transFileList(values: UploadFile[] | UploadFile) {
    let newFileList: UploadFile[];

    if (Array.isArray(values)) {
      newFileList = values.map(file => getRealFile(file));
    } else {
      newFileList = [getRealFile(values)];
    }

    setFileList(newFileList);
  }

  return [fileList, transFileList] as const;
};

export default useFileList;
